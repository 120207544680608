import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  Image,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import EmptyCategorySvg from '../assets/svg/empty-category.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
  getDateWithTime,
  ucfirst,
} from '../helpers/helpers';
import { toggleDarkMode } from '../redux/slices/settings';
import { filterMyStoreOrder, singleOrder } from '../redux/slices/orders';

export default function MyStoreOrders() {
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authState = useSelector(state => state.auth);

  const orderState = useSelector(state => state.order);

  const [activeMenu, setActiveMenu] = React.useState('unshipped');

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    // Get food menu to state

    setIsLoading(true);
    dispatch(
      filterMyStoreOrder({
        status: activeMenu,
        business_id: authState?.team,
      })
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [activeMenu]);

  const viewOrder = item => {
    dispatch(singleOrder(item));
    navigate('/order-details');
  };

  const viewMore = () => {
    dispatch(
      filterMyStoreOrder({
        status: activeMenu,
        business_id: authState?.team,
        page: orderState?.storeOrder?.current_page + 1,
      })
    );
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        //height={'100vh'}
        // pt={{ base: '120px', md: '75px' }}
        pb={{ base: '90px', md: '20px' }}
      >
        <Card
          p="10px"
          // bgColor={bg}
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf={'flex-start'}
              _hover={{ bg: 'transparent' }}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>NOTSTORE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex
          px={{ base: '20px', md: '20px' }}
          pt={{ base: '20px', md: '20px' }}
          mb={{ base: '20px', md: '20px' }}
          direction={'row'}
          overflowX="auto"
          maxWidth="100%"
        >
          {['unshipped', 'delivered', 'closed'].map((status, i) => (
            <Box
              key={i}
              borderBottomColor={
                status === activeMenu ? 'rgba(36, 107, 253, 1)' : 'transparent'
              }
              borderBottomWidth={'4px'}
              borderBottomLeftRadius={'2px'}
              borderBottomRightRadius={'2px'}
              paddingBottom={'5px'}
              marginRight={'20px'}
              onClick={() => setActiveMenu(status)}
            >
              <Text
                color={
                  status === activeMenu
                    ? 'rgba(36, 107, 253, 1)'
                    : 'rgba(155, 155, 155, 1)'
                }
                fontWeight={'500'}
                fontSize={'15px'}
                whiteSpace={'nowrap'}
              >
                {ucfirst(status)}
              </Text>
            </Box>
          ))}
        </Flex>

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          }} // 1 column on mobile, 3 columns on medium screens and above
          gap={{ base: 4, md: 8 }}
          px={{ base: '20px', md: '20px' }}
          pb={{ base: '20px', md: '20px' }}
        >
          {isLoading ? (
            <>
              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'50px'}
                width="100%"
              />

              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'50px'}
                width="100%"
              />

              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'50px'}
                width="100%"
              />
            </>
          ) : orderState?.storeOrder &&
            orderState?.storeOrder?.data &&
            orderState?.storeOrder?.data.length > 0 ? (
            orderState?.storeOrder?.data.map((item, index) => (
              <Card
                key={index}
                width={'100%'}
                py={4}
                px={6}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignSelf={'center'}
                alignItems={'center'}
                mb={3}
                onClick={() => viewOrder(item)}
              >
                <Flex flexDirection={'row'}>
                  <Text
                    fontSize={'43px'}
                    lineHeight={'40px'}
                    fontWeight={'400'}
                    mr={2}
                    color={
                      item?.order_paid
                        ? 'rgba(0, 210, 32, 1)'
                        : 'rgba(229, 20, 0, 1)'
                    }
                  >
                    {getDateWithTime(item?.created_at).hours12}
                  </Text>
                  <Flex alignItems={'center'} direction={'column'}>
                    <Text
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      fontWeight={'400'}
                      color={
                        item?.order_paid
                          ? 'rgba(0, 210, 32, 1)'
                          : 'rgba(229, 20, 0, 1)'
                      }
                    >
                      {getDateWithTime(item?.created_at).minutes}
                    </Text>
                    <Text
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      fontWeight={'400'}
                      color={'rgba(142, 139, 139, 0.8)'}
                    >
                      {getDateWithTime(item?.created_at).ampm}
                    </Text>
                  </Flex>
                </Flex>

                <Flex direction={'column'}>
                  <Text
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    fontWeight={'400'}
                  >
                    {formatDate(item?.created_at)}
                  </Text>

                  <Text
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    fontWeight={'400'}
                    color={'rgba(142, 139, 139, 0.8)'}
                  >
                    {autoEllipses(item?.recipient_email)}
                  </Text>
                </Flex>

                <Text fontSize={'14px'} lineHeight={'18px'} fontWeight={'400'}>
                  {formatNumberWithCommas(item?.order_amount)}{' '}
                  <span style={{ color: 'rgba(142, 139, 139, 0.8)' }}>
                    {item?.currency ?? 'USD'}
                  </span>
                </Text>
              </Card>
            ))
          ) : (
            <Center
              mt={'20vh'}
              px={{ base: '20px', md: '20px' }}
              flexDirection={'column'}
            >
              <Image src={EmptyCategorySvg} />

              <Text
                fontSize={'16px'}
                lineHeight={'24px'}
                fontWeight={'400'}
                mt={6}
                textAlign={'center'}
              >
                Seems like you haven’t sold any products yet.
              </Text>
            </Center>
          )}

          {orderState?.storeOrder?.current_page !==
            orderState?.storeOrder?.last_page &&
            !isLoading &&
            (orderState?.storeOrder?.data ?? []).length > 0 && (
              <Button
                width={'100%'}
                borderRadius={'16px'}
                bgColor={'rgba(215, 226, 244, 1)'}
                alignSelf={'center'}
                height={'58px'}
                onClick={() => viewMore()}
              >
                <Text
                  fontSize={'16px'}
                  lineHeight={'22.4px'}
                  fontWeight={'700'}
                  color={'rgba(36, 107, 253, 1)'}
                >
                  View More
                </Text>
              </Button>
            )}
        </Grid>
      </Flex>
    </>
  );
}
