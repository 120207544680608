import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  Heading,
  Image,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  useToast,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { register } from '../redux/slices/auth';
import {
  filterStoreProduct,
  getMyProducts,
  singleProduct,
} from '../redux/slices/product';
import { useParams } from 'react-router-dom';

import MessageSvg from '../assets/svg/message.svg';

import CallSvg from '../assets/svg/call.svg';

import DarkMessageSvg from '../assets/svg/dark-message.svg';

import DarkCallSvg from '../assets/svg/dark-call.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import { showAStore } from '../redux/slices/store';
import { ucfirst } from '../helpers/helpers';
import {
  getApplicationSettings,
  toggleDarkMode,
} from '../redux/slices/settings';

export default function Marketplace() {
  const { colorMode, toggleColorMode } = useColorMode();

  const toast = useToast();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const productState = useSelector(state => state.product);

  const storeState = useSelector(state => state.store);

  const settingsState = useSelector(state => state.settings);

  const [foodMenu, setFoodMenu] = React.useState([]);
  const [activeFoodMenu, setActiveFoodMenu] = React.useState('');

  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(
      filterStoreProduct({
        store_id: id,
      })
    );
    dispatch(
      showAStore({
        id,
      })
    );

    dispatch(getApplicationSettings());

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  React.useEffect(() => {
    // Get food menu to state
    if (
      productState?.storeProducts &&
      productState?.storeProducts?.data &&
      productState?.storeProducts?.data.length > 0
    ) {
      let menuData = [];
      for (let b = 0; b < productState?.storeProducts?.data.length; b++) {
        const element = productState?.storeProducts?.data[b];
        const foodCategories = element?.merchandise_categories ?? [];
        for (let c = 0; c < foodCategories.length; c++) {
          if (menuData.length == 0 && c == 0) {
            menuData.push({
              name: 'All Products',
              data: productState?.storeProducts?.data,
              active: true,
            });
          }
          const category = foodCategories[c];
          const alignFood = productState?.storeProducts?.data.filter(a =>
            (a?.merchandise_categories ?? []).includes(category)
          );
          let newData = menuData.find(a => a.name === category);
          if (!newData) {
            menuData.push({
              name: category,
              data: alignFood,
              active: false,
            });
          }
        }
      }
      setFoodMenu(menuData);
      setActiveFoodMenu(menuData[0]);
    }
  }, [productState?.storeProducts]);

  const showProduct = item => {
    dispatch(singleProduct(item));
    navigate(`/product/${id}`);
  };

  const handleMessaging = () => {
    // Ensure store_address is defined
    if (!storeState?.store?.store_address) {
      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Store phone number is not available.
          </Box>
        ),
      });

      return;
    }

    const telegramUrl = `https://t.me/${storeState?.store?.store_address}`;

    try {
      // Try opening the link using openTelegramLink
      window.Telegram.WebApp.openTelegramLink(telegramUrl);
    } catch (error) {
      alert('Error opening Telegram link: ' + error);
      // Fallback to opening in the default way if an error occurs
      window.location.href = telegramUrl;
    }
  };

  const handleCall = async () => {
    // Ensure store_phone is defined
    if (!storeState?.store?.store_phone) {
      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Store phone number is not available.
          </Box>
        ),
      });

      return;
    }

    try {
      await navigator.clipboard.writeText(storeState.store.store_phone);

      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Copied! phone number
          </Box>
        ),
      });
    } catch (error) {
      alert('Error copying call phone number: ' + error);
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        // pt={{ base: '120px', md: '75px' }}
        pb={{ base: '90px', md: '20px' }}
      >
        <Card
          p="10px"
          // bgColor={bg}
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/store/${id}`)}
              variant="ghost"
              alignSelf={'flex-start'}
              _hover={{ bg: 'transparent' }}
            >
              <Text fontSize={'16px'}>NOTSTORE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex direction={'column'} px={{ base: '20px', md: '20px' }} mb={4}>
          <Flex
            alignItems={'center'}
            my={4}
            direction={'row'}
            justifyContent={'space-between'}
          >
            {isLoading ? (
              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'30px'}
                width="150px"
              />
            ) : (
              <Heading textTransform="uppercase" pt={3} size={'sm'}>
                {storeState?.store?.store_name ?? 'Invalid Store'}
              </Heading>
            )}
          </Flex>

          {storeState?.store &&
            storeState?.store?.store_phone &&
            storeState?.store?.store_address && (
              <Flex alignItems={'center'} mt={2} mb={5} direction={'row'}>
                <Image
                  src={colorMode == 'light' ? MessageSvg : DarkMessageSvg}
                  mr={4}
                  onClick={() => handleMessaging()}
                />

                <Image
                  onClick={() => handleCall()}
                  src={colorMode == 'light' ? CallSvg : DarkCallSvg}
                />
              </Flex>
            )}

          {isLoading ? (
            <SkeletonText noOfLines={4} spacing="4" />
          ) : (
            <Text
              noOfLines={4}
              fontSize={'14px'}
              lineHeight={'19.6px'}
              fontWeight={'400'}
            >
              {storeState?.store?.store_description ??
                "Couldn't get store description"}
            </Text>
          )}
        </Flex>

        <Flex
          px={{ base: '20px', md: '20px' }}
          mb={{ base: '20px', md: '20px' }}
          direction={'row'}
          overflowX="auto"
          maxWidth="100%"
        >
          {foodMenu &&
            foodMenu.length > 0 &&
            foodMenu?.map((merch, i) => (
              <Box
                key={i}
                borderBottomColor={
                  merch?.name === activeFoodMenu?.name
                    ? 'rgba(36, 107, 253, 1)'
                    : 'transparent'
                }
                borderBottomWidth={'4px'}
                borderBottomLeftRadius={'2px'}
                borderBottomRightRadius={'2px'}
                paddingBottom={'5px'}
                marginRight={'20px'}
                onClick={() => setActiveFoodMenu(merch)}
              >
                <Text
                  color={
                    merch?.name === activeFoodMenu?.name
                      ? 'rgba(36, 107, 253, 1)'
                      : 'rgba(155, 155, 155, 1)'
                  }
                  whiteSpace={'nowrap'}
                  fontWeight={'500'}
                  fontSize={'15px'}
                >
                  {ucfirst(merch?.name)}
                </Text>
              </Box>
            ))}
        </Flex>

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          }} // 1 column on mobile, 3 columns on medium screens and above
          gap={{ base: 4, md: 8 }}
          px={{ base: '20px', md: '20px' }}
        >
          {isLoading ? (
            <Flex direction={'row'}>
              <Skeleton
                height="194px"
                width="172px"
                marginRight="10px"
                borderRadius="lg"
              />
              <Box>
                <SkeletonText noOfLines={1} mb={2} spacing="4" width="150px" />
                <SkeletonText
                  noOfLines={1}
                  spacing="4"
                  width="100px"
                  marginBottom="2"
                />
                <SkeletonText noOfLines={7} spacing="4" />
              </Box>
            </Flex>
          ) : (
            activeFoodMenu &&
            activeFoodMenu?.data.map((merchandise, key) => (
              <Box
                onClick={() => showProduct(merchandise)}
                maxW="sm"
                key={key}
                borderStyle={'none'}
                display={'flex'}
                flexDirection={'row'}
              >
                <Image
                  src={merchandise?.merchandise_pictures[0]}
                  alt={merchandise?.merchandise_name}
                  height={'194px'}
                  marginRight={'10px'}
                  width={'172px'}
                  borderRadius="lg"
                />

                <Box>
                  <Text
                    lineHeight={'15px'}
                    fontSize={'15px'}
                    mb={2}
                    fontWeight={'500'}
                  >
                    {merchandise?.merchandise_name}
                  </Text>
                  <Heading
                    mb={2}
                    size={'sm'}
                    fontWeight={'500'}
                    lineHeight={'15px'}
                    fontSize={'15px'}
                  >
                    {merchandise?.merchandise_amount}

                    <span
                      style={{
                        marginLeft: '5px',
                        color: 'rgba(142, 139, 139, 0.8)',
                      }}
                    >
                      {settingsState?.applicationSettings?.platform_currency
                        ? settingsState?.applicationSettings?.platform_currency
                            ?.alphabetic_code
                        : 'USD'}
                    </span>
                  </Heading>

                  <Text
                    fontSize={'15px'}
                    lineHeight={'17px'}
                    fontWeight={'400'}
                    noOfLines={5}
                  >
                    {merchandise?.merchandise_description}
                  </Text>
                </Box>
              </Box>
            ))
          )}
        </Grid>
      </Flex>
    </>
  );
}
