import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Switch,
  Heading,
  Image,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { Player } from '@lottiefiles/react-lottie-player';

import { useLocation, useNavigate } from 'react-router-dom';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';

export default function NotFound() {
  const toast = useToast();

  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/notfound`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Text fontSize={'16px'}>NOT FOUND</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Center
          mt={'20vh'}
          px={{ base: '20px', md: '20px' }}
          flexDirection={'column'}
        >
          {/* <Image src={EmptyCartSvg} /> */}

          <Player
            src="https://lottie.host/708627a5-012e-4224-b2cc-dd27075b9b12/QkcSMwk954.json"
            className="player"
            loop
            autoplay
          />

          <Text
            fontSize={'16px'}
            lineHeight={'24px'}
            fontWeight={'400'}
            mt={6}
            textAlign={'center'}
          >
            "Are you lost?.....Visit out telegram{' '}
            <a
              style={{ color: 'blue' }}
              href="t.me/thenotstorebot/thenotstorebot"
            >
              BOT"
            </a>
          </Text>
        </Center>
      </Flex>
    </>
  );
}
