import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Switch,
  Heading,
  Image,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { Player } from '@lottiefiles/react-lottie-player';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import { MdClose } from 'react-icons/md';

import axios from 'axios';

import { getMyCart } from '../redux/slices/cart';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';

export default function Cart() {
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const cartState = useSelector(state => state.cart);

  const [currentPrice, setCurrentPrice] = React.useState('');

  const { id } = useParams();

  const calculatePrice = () => {
    const totalPrice = cartState.myCart.reduce((total, item) => {
      return total + parseInt(item?.merchandise_amount) * (item?.count ?? 1);
    }, 0);

    return totalPrice;
  };

  const isSelected = value => {
    const exist = cartState?.myCart.filter(a => a?.id === value?.id);

    if (exist && exist.length > 0) {
      return exist[0];
    }
    return null;
  };

  const addToCart = value => {
    const exist = cartState?.myCart.find(item => item.id === value.id);

    if (exist) {
      const updatedItem = { ...exist, count: (exist?.count ?? 1) + 1 };

      const newCart = cartState.myCart.map(item =>
        item.id === value.id ? updatedItem : item
      );

      dispatch(getMyCart(newCart));
    } else {
      const newItem = [...cartState?.myCart, value];
      dispatch(getMyCart(newItem));
    }
  };

  const minusCategoryItem = value => {
    const exist = cartState?.myCart.find(item => item.id === value.id);

    if (exist) {
      if ((exist?.count ?? 1) === 1) {
        removeCategoryItem(value);
      } else {
        const updatedItem = { ...exist, count: exist.count - 1 };

        const newCart = cartState.myCart.map(item =>
          item.id === value.id ? updatedItem : item
        );

        dispatch(getMyCart(newCart));
      }
    }
  };

  const removeCategoryItem = key => {
    const c = cartState?.myCart.filter(function (e, i) {
      return e?.id !== key?.id;
    });

    dispatch(getMyCart(c));
  };

  React.useEffect(() => {
    if (cartState.myCart && cartState.myCart?.length == 0) {
      return;
    }
    fetchExchange();
  }, [cartState.myCart]);

  const fetchExchange = async () => {
    const exchange = await axios.get(
      'https://tonapi.io/v2/rates?tokens=ton&currencies=usd'
    );

    if (exchange?.status == 200) {
      setCurrentPrice(exchange?.data?.rates?.TON?.prices['USD']);
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/store/${id}`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>HAND CART</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        {cartState.myCart && cartState.myCart?.length == 0 ? (
          <Center
            mt={'20vh'}
            px={{ base: '20px', md: '20px' }}
            flexDirection={'column'}
          >
            {/* <Image src={EmptyCartSvg} /> */}

            <Player
              src="https://lottie.host/691e1c10-f453-4c8f-8555-dc1d15d082e2/jRpnWhrzeh.json"
              className="player"
              loop
              autoplay
            />

            <Text
              fontSize={'16px'}
              lineHeight={'24px'}
              fontWeight={'400'}
              mt={6}
              textAlign={'center'}
            >
              "Fill your cart with joy! Explore our latest collections now."
            </Text>
          </Center>
        ) : (
          <>
            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} // 1 column on mobile, 3 columns on medium screens and above
              gap={{ base: 4, md: 8 }} // Spacing between items, 4 on mobile, 8 on medium screens and above
              // Other grid properties as needed

              px={{ base: '20px', md: '20px' }}
              pb={{ base: '20px', md: '20px' }}
            >
              {cartState?.myCart &&
                cartState?.myCart.length > 0 &&
                cartState?.myCart?.map((item, i) => (
                  <React.Fragment key={i}>
                    <Card
                      direction={{ base: 'column', sm: 'row' }}
                      overflow="hidden"
                      variant="outline"
                      flexDirection={'row'}
                      display={'flex'}
                      // justifyContent={'space-between'}
                      p={2}
                    >
                      <Image
                        borderRadius={'10px'}
                        objectFit="cover"
                        maxH={{ base: '100px', sm: '100px' }}
                        maxW={{ base: '100px', sm: '100px' }}
                        src={item?.merchandise_pictures[0]}
                        alt="Caffe Latte"
                      />

                      <Flex
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        ml={7}
                        width={'100%'}
                      >
                        <Flex
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Heading size="sm">{item?.merchandise_name}</Heading>

                          <Button
                            onClick={() => removeCategoryItem(item)}
                            textColor={'black'}
                            variant={'unstyled'}
                          >
                            <MdClose
                              color={colorMode == 'light' ? 'black' : 'white'}
                              size={20}
                            />
                          </Button>
                        </Flex>

                        <Flex
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Text fontSize={'14px'}>
                            {isSelected(item) ? (
                              <>
                                {parseInt(item?.merchandise_amount) *
                                  (isSelected(item)?.count ?? 1)}
                              </>
                            ) : (
                              <>{item?.merchandise_amount ?? '--'}</>
                            )}

                            <span style={{ marginLeft: '5px', color: 'gray' }}>
                              USD
                            </span>
                          </Text>

                          <Flex
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Button
                              onClick={() => minusCategoryItem(item)}
                              textColor={'gray'}
                              variant={'unstyled'}
                            >
                              -
                            </Button>

                            <Text>{isSelected(item)?.count ?? 1}</Text>

                            <Button
                              onClick={() => addToCart(item)}
                              textColor={'gray'}
                              variant={'unstyled'}
                            >
                              +
                            </Button>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Card>
                  </React.Fragment>
                ))}
            </Grid>

            <Box width={'100%'} py={7} position={'fixed'} bottom={'7%'}>
              <Divider mb={8} />

              <Flex
                px={5}
                alignItems={'center'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Box>
                  <Text lineHeight={'18px'} fontSize={'14px'}>
                    {calculatePrice()}

                    <span style={{ marginLeft: '5px', color: 'gray' }}>
                      USD
                    </span>
                  </Text>

                  {currentPrice && (
                    <Text
                      fontWeight={'400'}
                      fontSize={'10px'}
                      lineHeight={'18px'}
                      color={'rgba(142, 139, 139, 1)'}
                    >
                      {`${currentPrice.toFixed(2)} USD = 1 TON`}
                    </Text>
                  )}
                </Box>

                <Button
                  alignSelf={'flex-start'}
                  h="2.5rem"
                  borderRadius={'8px'}
                  width={'100px'}
                  size="sm"
                  bgColor={'rgba(36, 107, 253, 1)'}
                  onClick={() => navigate(`/checkout/${id}`)}
                  textColor={'white'}
                >
                  {'Checkout'}
                </Button>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </>
  );
}
