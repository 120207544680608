import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Image,
  Stack,
  StackDivider,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Center,
  Grid,
  SimpleGrid,
  Radio,
  extendTheme,
  Flex,
  Card,
  CardBody,
  Switch,
  useColorMode,
  useColorModeValue,
  Textarea,
  useToast,
  Select,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import {
  getAllCategories,
  selectCategoryAction,
} from '../redux/slices/categories';

import {
  approveProductStatus,
  createProduct,
  deleteProduct,
  resetCreated,
  unApproveProductStatus,
  updateProduct,
} from '../redux/slices/product';

import UploadSvg from '../assets/svg/upload.svg';
import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';
import ProductCategorySvg from '../assets/svg/product-category.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import {
  getApplicationSettings,
  savedForm,
  toggleDarkMode,
} from '../redux/slices/settings';

import { FaChevronRight } from 'react-icons/fa';

import ListSvg from '../assets/svg/list.svg';

import DropdownArrowSvg from '../assets/svg/dropdown-arrow.svg';

import BlackDropdownArrowSvg from '../assets/svg/blackdropdown.svg';

export default function EditProduct() {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('black', 'white');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authState = useSelector(state => state.auth);
  const productState = useSelector(state => state.product);
  const categoryState = useSelector(state => state.category);

  const storeState = useSelector(state => state.store);

  const settingsState = useSelector(state => state.settings);

  const [input, setInput] = React.useState({
    merchandise_name: '',
    merchandise_description: '',
    merchandise_amount: '',
    merchandise_categories: [],
    format: '',
  });

  React.useEffect(() => {
    setInput({
      ...input,
      merchandise_name: productState?.singleProduct?.merchandise_name ?? '',
      merchandise_description:
        productState?.singleProduct?.merchandise_description ?? '',
      merchandise_amount: productState?.singleProduct?.merchandise_amount ?? '',
      format: productState?.singleProduct?.format ?? 'physical',
      merchandise_categories:
        productState?.singleProduct?.merchandise_categories ?? [],
    });
  }, [productState?.singleProduct]);

  React.useEffect(() => {
    if (settingsState?.form) {
      const {
        merchandise_name,
        merchandise_description,
        merchandise_amount,
        format,
        base64_photos,
        imageUrl,
      } = settingsState?.form;

      if (categoryState?.selectedCategory) {
        let category = [];
        category.push(categoryState?.selectedCategory);
        setInput({
          ...input,
          merchandise_amount,
          merchandise_name,
          format,
          merchandise_description,
          merchandise_categories: category,
        });
      }

      const validUpload = base64_photos.filter(item => item !== null);
      if (validUpload.length > 0) {
        setImageUrl(imageUrl);
        setBase64(base64_photos);
      }

      dispatch(savedForm(null));
      dispatch(selectCategoryAction(''));
    }
  }, [categoryState?.selectedCategory]);

  React.useEffect(() => {
    if (productState?.created) {
      setTimeout(() => {
        dispatch(resetCreated());

        toast({
          position: 'top-right',
          render: () => (
            <Box color="white" p={3} bg="blue.500">
              Updated successfully
            </Box>
          ),
        });

        navigate('/products');
      }, 1000);
    }
  }, [productState?.reload]);

  React.useEffect(() => {
    dispatch(getApplicationSettings());
  }, []);

  const [imageUrl, setImageUrl] = React.useState([null, null, null]);

  const [base64_photos, setBase64] = React.useState([null, null, null]);

  const [errors, setErrors] = React.useState({});

  const validateForm = () => {
    let formErrors = {};

    if (
      input.merchandise_name.length < 2 ||
      input.merchandise_name.length > 60
    ) {
      formErrors.merchandise_name =
        'name is required and length greater than 2 and less than 60';
    }

    if (
      input.merchandise_description.length < 2 ||
      input.merchandise_description.length > 60
    ) {
      formErrors.merchandise_description =
        'Description is required and length greater than 2 and less than 500';
    }

    if (input.merchandise_amount < 0) {
      formErrors.merchandise_amount =
        'Amount is required and length greater than 2 and less than 60';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const updateImageUrl = (index, url, base64) => {
    setImageUrl(prevUrls => {
      const newUrls = [...prevUrls];
      newUrls[index] = url;
      return newUrls;
    });

    setBase64(prevUrls => {
      const newUrls = [...prevUrls];
      newUrls[index] = base64;
      return newUrls;
    });
  };

  const handleFileChange = (event, index) => {
    const selectedFile = event.target.files[0];

    const url = URL.createObjectURL(selectedFile);

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      updateImageUrl(index, url, reader.result);
    };
    reader.onerror = error => {
      console.error('Error reading file:', error);
      setErrors({ ...errors, photo: 'Error reading file' });
    };

    setErrors({ ...errors, photo: '' });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(savedForm(null));

      const validUpload = base64_photos.filter(item => item !== null);

      // Create a new array to store the final pictures
      const finalPhotos =
        productState?.singleProduct?.merchandise_pictures.filter(
          (item, index) =>
            // If there's no base64 photo at the same index, keep it, otherwise remove it because it was replaced
            base64_photos[index] === null
        );

      if (validUpload.length > 0) {
        if (finalPhotos.length > 0) {
          dispatch(
            updateProduct({
              ...input,
              id: productState?.singleProduct?.id,
              base64_photos: validUpload,
              url_photos: finalPhotos,
              business_id: authState?.team,
              store_id: storeState?.myStores[0]?.id,
            })
          );

          navigate('/products');
          return;
        }
        dispatch(
          updateProduct({
            ...input,
            id: productState?.singleProduct?.id,
            base64_photos: validUpload,
            business_id: authState?.team,
            store_id: storeState?.myStores[0]?.id,
          })
        );

        navigate('/products');
        return;
      }

      dispatch(
        updateProduct({
          ...input,

          id: productState?.singleProduct?.id,
          business_id: authState?.team,
          store_id: storeState?.myStores[0]?.id,
        })
      );

      navigate('/products');
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const saveCurrentForm = () => {
    dispatch(
      savedForm({
        ...input,
        imageUrl,
        base64_photos,
      })
    );
  };

  const onDeleteProduct = () => {
    dispatch(
      deleteProduct({
        id: productState?.singleProduct?.id,
      })
    );

    navigate('/products');
  };

  const approveProduct = id => {
    dispatch(
      approveProductStatus({
        id: id,
      })
    );
  };

  const unApproveProduct = id => {
    dispatch(
      unApproveProductStatus({
        id: id,
      })
    );
  };

  const handleSelectProductType = e => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    const selectedName = selectedOption.getAttribute('data-name');

    setInput({ ...input, format: selectedName });
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/products`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>EDIT PRODUCT</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <form onSubmit={handleSubmit}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            px={{ base: '20px', md: '20px' }}
          >
            <Text
              fontSize="16px"
              fontWeight="500"
              lineHeight="22.4px"
              color={text}
              marginBottom={4}
            >
              Images
            </Text>
            <Grid
              templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)' }} // 1 column on mobile, 3 columns on medium screens and above
              gap={{ base: 1, md: 8 }} // Spacing between items, 4 on mobile, 8 on medium screens and above
              // Other grid properties as needed
              // pt={{ base: '30px', md: '20px' }}
              // px={{ base: '10px', md: '20px' }}
              // pb={{ base: '30px', md: '20px' }}
              alignSelf={'center'}
            >
              <Box
                h={'136px'}
                w={'120px'}
                bgImage={
                  imageUrl[0] ||
                  ((productState?.singleProduct?.merchandise_pictures ?? [])
                    .length > 0
                    ? productState?.singleProduct?.merchandise_pictures[0]
                    : null) ||
                  UploadSvg
                }
                bgRepeat={'no-repeat'}
                bgSize="contain"
                bgPosition="100%"
              >
                <Input
                  accept="image/*"
                  style={{
                    display: 'none',
                    width: '100%',
                    height: '100%',
                  }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={e => handleFileChange(e, 0)}
                />
                <label
                  htmlFor="contained-button-file"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></label>
              </Box>

              <Box
                h={'136px'}
                w={'120px'}
                bgImage={
                  imageUrl[1] ||
                  ((productState?.singleProduct?.merchandise_pictures ?? [])
                    .length > 1
                    ? productState?.singleProduct?.merchandise_pictures[1]
                    : null) ||
                  UploadSvg
                }
                bgRepeat={'no-repeat'}
                bgSize="contain"
                bgPosition="100%"
              >
                <Input
                  accept="image/*"
                  style={{
                    display: 'none',
                    width: '100%',
                    height: '100%',
                  }}
                  id="contained-button-file-one"
                  multiple
                  type="file"
                  onChange={e => handleFileChange(e, 1)}
                />
                <label
                  htmlFor="contained-button-file-one"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></label>
              </Box>

              <Box
                h={'136px'}
                w={'120px'}
                bgImage={
                  imageUrl[2] ||
                  ((productState?.singleProduct?.merchandise_pictures ?? [])
                    .length > 2
                    ? productState?.singleProduct?.merchandise_pictures[2]
                    : null) ||
                  UploadSvg
                }
                bgRepeat={'no-repeat'}
                bgSize="contain"
                bgPosition="100%"
              >
                <Input
                  accept="image/*"
                  style={{
                    display: 'none',
                    width: '100%',
                    height: '100%',
                  }}
                  id="contained-button-file-two"
                  multiple
                  type="file"
                  onChange={e => handleFileChange(e, 2)}
                />
                <label
                  htmlFor="contained-button-file-two"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                ></label>
              </Box>
            </Grid>
            {errors?.photo && (
              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight="14px"
                textAlign={'start'}
                color="red.500"
                marginBottom={'10px'}
              >
                {errors.photo}
              </Text>
            )}

            {/* <Text
              fontSize="20px"
              fontWeight="300"
              lineHeight="26px"
              color={text}
              marginTop={10}
              marginBottom={4}
            >
              GENERAL
            </Text> */}

            <Stack marginTop={10} spacing="4">
              <Card width={'100%'}>
                <FormControl
                  id="merchandise_name"
                  isInvalid={errors.merchandise_name}
                >
                  <Input
                    type="text"
                    height={'56px'}
                    pl={5}
                    variant="unstyled"
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    fontWeight={'500'}
                    name="merchandise_name"
                    borderColor={'rgba(208, 207, 207, 1)'}
                    width={{ sm: '330px', md: '382px' }}
                    value={input.merchandise_name}
                    placeholder="product name"
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              </Card>

              <Card>
                <FormControl
                  id="merchandise_amount"
                  isInvalid={errors.merchandise_amount}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    pr={4}
                  >
                    <Input
                      type="number"
                      height={'56px'}
                      pl={5}
                      variant="unstyled"
                      fontSize={'12px'}
                      lineHeight={'18px'}
                      fontWeight={'500'}
                      name="merchandise_amount"
                      borderColor={'rgba(208, 207, 207, 1)'}
                      width={{ sm: '340px', md: '382px' }}
                      placeholder="Price"
                      value={input.merchandise_amount}
                      onChange={handleChange}
                    />

                    <Text
                      fontSize={'12px'}
                      lineHeight={'18px'}
                      fontWeight={'500'}
                    >
                      {settingsState?.applicationSettings?.platform_currency
                        ? settingsState?.applicationSettings?.platform_currency
                            ?.alphabetic_code
                        : 'USD'}
                    </Text>
                  </Box>

                  <FormErrorMessage>
                    {errors.merchandise_amount}
                  </FormErrorMessage>
                </FormControl>
              </Card>

              <Card pl={5}>
                <Select
                  height={'56px'}
                  iconSize="10px"
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  fontWeight={'500'}
                  color={'gray.500'}
                  icon={
                    <Image
                      src={
                        colorMode == 'light'
                          ? BlackDropdownArrowSvg
                          : DropdownArrowSvg
                      }
                    />
                  }
                  placeholder={
                    productState?.singleProduct?.format ?? 'Product Type'
                  }
                  variant="unstyled"
                  onChange={handleSelectProductType}
                >
                  <option
                    selected={input?.format == 'physical'}
                    data-name={'physical'}
                    value={'physical'}
                  >
                    {'Physical'}
                  </option>

                  <option
                    selected={input?.format == 'digital'}
                    data-name={'digital'}
                    value={'digital'}
                  >
                    {'Digital'}
                  </option>
                </Select>
              </Card>

              <Card width={'100%'}>
                <CardBody>
                  <FormControl
                    marginBottom={10}
                    id="merchandise_description"
                    isInvalid={errors.merchandise_description}
                  >
                    <Textarea
                      type="text"
                      pr={2}
                      variant="unstyled"
                      noOfLines={4}
                      fontSize={'12px'}
                      lineHeight={'18px'}
                      fontWeight={'500'}
                      name="merchandise_description"
                      borderColor={'rgba(208, 207, 207, 1)'}
                      height={'50px'}
                      width={{ sm: '328px', md: '380px' }}
                      placeholder="Enter product description"
                      value={input.merchandise_description}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>
                      {errors.merchandise_description}
                    </FormErrorMessage>
                  </FormControl>
                </CardBody>
              </Card>
            </Stack>

            <Card
              mt={4}
              p={3}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              onClick={() => {
                saveCurrentForm();
                navigate('/select-category');
              }}
            >
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Image src={ProductCategorySvg} mr={3} />
                <Text
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  fontWeight={'500'}
                  color={
                    colorMode == 'light' ? 'rgba(52, 75, 103, 1)' : 'white'
                  }
                >
                  Category{' '}
                  {input?.merchandise_categories.length > 0
                    ? `: ${input?.merchandise_categories[0]}`
                    : ''}
                </Text>
              </Box>

              <FaChevronRight />
            </Card>

            <Card
              mt={4}
              p={3}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Image src={ListSvg} mr={3} />
                <Text
                  fontSize={'12px'}
                  lineHeight={'18px'}
                  fontWeight={'500'}
                  color={
                    colorMode == 'light' ? 'rgba(52, 75, 103, 1)' : 'white'
                  }
                >
                  Availability{' '}
                </Text>
              </Box>

              <Switch
                colorScheme="green"
                isChecked={productState?.singleProduct?.business_approved}
                onChange={() => {
                  if (productState?.singleProduct?.business_approved) {
                    unApproveProduct(productState?.singleProduct?.id);
                    return;
                  }
                  approveProduct(productState?.singleProduct?.id);
                }}
                size={'lg'}
              />
            </Card>

            <Center flexDirection={'column'} width={'100%'} marginY={10}>
              <Button
                mt={4}
                alignSelf={'center'}
                borderRadius={'16px'}
                width={'100%'}
                height={'54px'}
                backgroundColor={'rgba(215, 226, 244, 1)'}
                isLoading={productState?.isLoading}
                padding={2}
                onClick={() => onDeleteProduct()}
              >
                <Text
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="26px"
                  color={'rgba(36, 107, 253, 1)'}
                >
                  Remove
                </Text>
              </Button>

              <Button
                mt={4}
                alignSelf={'center'}
                borderRadius={'16px'}
                width={'100%'}
                height={'54px'}
                backgroundColor={
                  !input?.merchandise_name || !input?.merchandise_amount
                    ? 'gray'
                    : 'rgba(36, 107, 253, 1)'
                }
                isLoading={productState?.isLoading}
                padding={2}
                isDisabled={
                  !input?.merchandise_name || !input?.merchandise_amount
                }
                type="submit"
              >
                <Text
                  fontSize="16px"
                  fontWeight="400"
                  lineHeight="26px"
                  color={
                    !input?.merchandise_name || !input?.merchandise_description
                      ? 'black'
                      : 'white'
                  }
                >
                  Save
                </Text>
              </Button>
            </Center>
          </Box>
        </form>
      </Flex>
    </>
  );
}
