import React from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Card,
  Center,
  Input,
  Image,
  useColorMode,
  useToast,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';

import { formatNumberWithCommas } from '../helpers/helpers';
import { toggleDarkMode } from '../redux/slices/settings';

import WalletSvg from '../assets/svg/wallet.svg';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import axios from 'axios';
import { withdrawFromWallet } from '../redux/slices/withdrawal';

import { showMyWallet, storeWallet } from '../redux/slices/wallet';

export default function Withdraw() {
  const { colorMode, toggleColorMode } = useColorMode();

  const toast = useToast();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { state } = useLocation();

  const withdrawState = useSelector(state => state.withdraw);

  const walletState = useSelector(state => state.wallet);

  const settingsState = useSelector(state => state.settings);

  const [input, setInput] = React.useState({ amount: '', address: '' });

  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPrice, setCurrentPrice] = React.useState();

  const handleChange = event => {
    const { name, value } = event.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  React.useEffect(() => {
    fetchExchange();
  }, []);

  React.useEffect(() => {
    if (withdrawState?.error) {
      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="red.500">
            {withdrawState?.error}
          </Box>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawState?.error]);

  const fetchExchange = async () => {
    const exchange = await axios.get(
      'https://tonapi.io/v2/rates?tokens=ton&currencies=usd'
    );

    if (exchange?.status === 200 && exchange?.data && exchange?.data?.rates) {
      setCurrentPrice(exchange?.data?.rates?.TON?.prices['USD']);
    }
  };

  const findAddress = async address => {
    const wallet = walletState?.myWallet?.data.find(
      item => item?.id === state?.id
    );
    if (!wallet) return [];

    const matchedAddresses = Object.values(wallet.coin_details).filter(
      detail => detail.address === address
    );
    return matchedAddresses;
  };

  const initiateWithdraw = payoutTypeId => {
    dispatch(
      withdrawFromWallet({
        amount: input?.amount,
        wallet_id: state?.id,
        payout_type: 'coin',
        payout_type_id: payoutTypeId,
        callback: () => dispatch(showMyWallet()),
      })
    );
    setIsLoading(false);
    navigate('/all-withdrawals');
  };

  const withdraw = async () => {
    setIsLoading(true);
    dispatch(showMyWallet());

    const existingWallet = await findAddress(input.address);

    if (existingWallet.length > 0) {
      initiateWithdraw(existingWallet[0].id);

      return;
    }

    // Add new wallet address if it doesn’t exist
    dispatch(
      storeWallet({
        name: state?.currency_name,
        symbol: state?.currency_code,
        address: input.address,
        id: state?.id,
        callback: response => {
          const info = Object.values(response);
          initiateWithdraw(info[0].id);
        },
      })
    );
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        //height={'100vh'}
        // pt={{ base: '120px', md: '75px' }}
        pb={{ base: '90px', md: '20px' }}
      >
        <Card
          p="10px"
          // bgColor={bg}
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf={'flex-start'}
              _hover={{ bg: 'transparent' }}
            >
              <Image
                src={colorMode === 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>WITHDRAWAL</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode === 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Text
          fontSize={'14px'}
          lineHeight={'18px'}
          my={'20px'}
          fontWeight={'500'}
          color={'rgba(142, 139, 139, 1)'}
          px={{ base: '20px', md: '20px' }}
        >
          Balance
        </Text>

        <Flex
          mt={'5px'}
          mb={'25px'}
          direction={'row'}
          px={{ base: '20px', md: '20px' }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text
            fontSize={'40px'}
            lineHeight={'60px'}
            fontWeight={'400'}
            alignItems={'flex-end'}
          >
            {formatNumberWithCommas(state?.balance ?? 0)}
            <span
              style={{
                marginLeft: '5px',
                color: 'rgba(142, 139, 139, 1)',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '30px',
              }}
            >
              {state?.currency_code ??
                settingsState?.applicationSettings?.platform_currency
                  ?.alphabetic_code ??
                'USD'}
            </span>
          </Text>
        </Flex>

        <Center px={{ base: '20px', md: '20px' }} flexDirection={'column'}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction="row"
            mb={'25px'}
          >
            <Text
              fontSize={'10px'}
              lineHeight={'12px'}
              fontWeight={'400'}
              textAlign={'center'}
              color={'rgba(155, 155, 155, 1)'}
            >
              "Enter the amount and proceed with the withdrawal. Transaction
              processing may take 10-15 minutes."
            </Text>
          </Flex>

          <Card
            mt={10}
            p={4}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'row'}
              width={'100%'}
            >
              <Image src={WalletSvg} mr={3} />

              <Input
                type="text"
                width={'100%'}
                pl={5}
                variant="unstyled"
                fontSize={'12px'}
                lineHeight={'18px'}
                fontWeight={'500'}
                name="address"
                borderColor={'rgba(208, 207, 207, 1)'}
                // width={{ sm: '330px', md: '382px' }}
                value={input.address}
                placeholder="wallet address"
                _placeholder={{ color: 'gray.500' }}
                onChange={handleChange}
                data-testid="address"
              />
            </Box>
          </Card>

          <Text
            fontSize={'10px'}
            fontWeight={'400'}
            lineHeight={'12px'}
            color={'rgba(52, 75, 103, 1)'}
            mb={8}
            mt={2}
            alignSelf={'flex-start'}
          >
            Easily receive payments by adding the correct wallet address.
          </Text>

          <Card
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pr={4}
            width={'100%'}
          >
            <Input
              type="number"
              height={'56px'}
              width={'100%'}
              pl={5}
              variant="unstyled"
              fontSize={'12px'}
              lineHeight={'18px'}
              fontWeight={'500'}
              name="amount"
              borderColor={'rgba(208, 207, 207, 1)'}
              // width={{ sm: '340px', md: '382px' }}
              placeholder={`Amount (${state?.currency_code ?? 'USD'})`}
              _placeholder={{ color: 'gray.500' }}
              value={input.amount}
              onChange={handleChange}
            />

            {/* <Popover>
              <PopoverTrigger>
                <FaInfoCircle color={'rgba(108, 117, 125, 1)'} />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Withdrawal Price</PopoverHeader>
                <PopoverBody fontSize={'12px'}>
                  Set the amount you wish to withdraw. The currency is{' '}
                  {state?.currency_code ??
                    settingsState?.applicationSettings?.platform_currency
                      ?.alphabetic_code ??
                    'USD'}
                </PopoverBody>
              </PopoverContent>
            </Popover> */}
          </Card>
        </Center>

        <Box my={10} px={{ base: '20px', md: '20px' }}>
          {currentPrice && state?.currency_code === 'TON' && (
            <Text
              fontWeight={'400'}
              fontSize={'10px'}
              lineHeight={'18px'}
              color={'rgba(142, 139, 139, 1)'}
            >
              {`${currentPrice.toFixed(2)} USD = 1 TON`}
            </Text>
          )}
        </Box>

        <Center width={'100%'} px={{ base: '20px', md: '20px' }}>
          <Button
            mt={4}
            alignSelf={'center'}
            borderRadius={'16px'}
            width={'100%'}
            height={'54px'}
            backgroundColor={!input?.amount ? 'gray' : 'rgba(36, 107, 253, 1)'}
            padding={2}
            isLoading={isLoading}
            isDisabled={!input?.amount || !input.address}
            onClick={() => withdraw()}
            loadingText="processing..."
          >
            <Text
              fontSize="16px"
              fontWeight="400"
              lineHeight="26px"
              color={!input?.amount ? 'black' : 'white'}
            >
              Withdraw Funds
            </Text>
          </Button>
        </Center>
      </Flex>
    </>
  );
}
