import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const storeWallet = createAsyncThunk(
  'wallet/storeWallet',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    const formData = new FormData();

    let callback = () => {};

    if (data?.callback) {
      callback = data.callback;

      delete data.callback;
    }

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/wallet/coin/store`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    });
    const response = await res.json();

    if (response?.status_code == 201) {
      callback(response?.data);
      return response?.data;
    }

    return {};
  }
);

export const storeUserWallet = createAsyncThunk(
  'wallet/storeUserWallet',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    const formData = new FormData();

    let callback = () => {};

    if (data?.callback) {
      callback = data.callback;

      delete data.callback;
    }

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/wallet/store`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    });
    const response = await res.json();

    if (response?.status_code == 201) {
      callback(response.data);
      return response?.data;
    }

    return {};
  }
);

export const deleteWallet = createAsyncThunk(
  'wallet/deleteWallet',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/wallet/coin/delete`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    });
    const response = await res.json();

    return response?.data;
  }
);

export const getMyWallet = createAsyncThunk(
  'wallet/getMyWallet',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    try {
      const res = await fetch(`${apiUrl}/wallet/me`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,

          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const showAWallet = createAsyncThunk(
  'wallet/showAWallet',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;
    try {
      const res = await fetch(
        `${apiUrl}/wallet/show?user_id=${data['user_id']}&properties=1`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
            Team: team,
            'Content-Type': 'application/json',
          },
        }
      );
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const showMyWallet = createAsyncThunk(
  'wallet/showMyWallet',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    let callback = () => {};

    if (data?.callback) {
      callback = data.callback;

      delete data.callback;
    }
    try {
      const res = await fetch(`${apiUrl}/wallet/me?page=${data?.page ?? 1}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          Team: team,
          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();
      if (response?.status_code !== 200) {
        return thunkAPI.rejectWithValue(response?.message);
      }
      if ((response?.data?.data ?? []).length > 0) {
        callback(response.data);
        return response?.data;
      }
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    wallet: {},
    myWallet: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(storeWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(storeWallet.fulfilled, (state, action) => {
      state.isLoading = false;

      state.wallet = action.payload;
    });
    builder.addCase(storeWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteWallet.fulfilled, (state, action) => {
      state.isLoading = false;

      state.wallet = action.payload;
    });
    builder.addCase(deleteWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getMyWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyWallet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.myWallet = action.payload;
    });
    builder.addCase(getMyWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(showAWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(showAWallet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.myWallet = action.payload;
    });
    builder.addCase(showAWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(showMyWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(showMyWallet.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.current_page !== 1) {
        state.myWallet = {
          ...action.payload,
          data: [
            ...(state.myWallet?.data ?? []),
            ...(action.payload.data ?? []),
          ],
        };
        return;
      }
      state.myWallet = action.payload;
    });
    builder.addCase(showMyWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default walletSlice.reducer;
