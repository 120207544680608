import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import categoryReducer from './slices/categories';
import tagReducer from './slices/tags';
import storeReducer from './slices/store';
import productReducer from './slices/product';
import cartReducer from './slices/cart';
import settingsReducer from './slices/settings';
import locationReducer from './slices/location';
import paymentReducer from './slices/payments';
import orderReducer from './slices/orders';
import walletReducer from './slices/wallet';
import rewardReducer from './slices/rewards';
import withdrawalReducer from './slices/withdrawal';

export default configureStore({
  reducer: {
    auth: authReducer,
    category: categoryReducer,
    tag: tagReducer,
    store: storeReducer,
    product: productReducer,
    cart: cartReducer,
    settings: settingsReducer,
    location: locationReducer,
    payment: paymentReducer,
    order: orderReducer,
    wallet: walletReducer,
    reward: rewardReducer,
    withdraw: withdrawalReducer,
  },
});
