import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Stack,
  StackDivider,
  Text,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  Heading,
  Image,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { sendMessageWithButton } from '../helpers/telegram';

import WalletSvg from '../assets/svg/wallet.svg';

import TonSvg from '../assets/svg/ton-logo.svg';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import {
  useTonConnectModal,
  useTonWallet,
  useTonConnectUI,
} from '@tonconnect/ui-react';

import {
  getMyCart,
  payoutCart,
  sendBocAction,
  userCheckout,
} from '../redux/slices/cart';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';

import { beginCell, Cell } from '@ton/ton';

import TonWeb from 'tonweb';
import { getPaymentProviders } from '../redux/slices/payments';
import { autoEllipses } from '../helpers/helpers';

export default function PaymentMethod() {
  const toast = useToast();

  const { colorMode, toggleColorMode } = useColorMode();

  const { open, close } = useTonConnectModal();

  const wallet = useTonWallet();

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const cartState = useSelector(state => state.cart);
  const paymentState = useSelector(state => state.payment);
  const authState = useSelector(state => state.auth);
  const storeState = useSelector(state => state.store);

  let chatInfo = window.Telegram.WebApp.initDataUnsafe.user;

  React.useEffect(() => {
    if (authState.token) {
      dispatch(getPaymentProviders());
    }
  }, [authState.token]);

  const { state } = useLocation();

  const pay = async responseData => {
    const body = beginCell()
      .storeUint(0, 32) // Write 32 zero bits to indicate a text comment will follow
      .storeStringTail(responseData?.id) // Write the text comment
      .endCell();

    const myTransaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
      messages: [
        {
          address: paymentState?.paymentProviders?.cello_wallet,
          //amount: '6485787033',
          amount: (responseData.provider?.cello?.amount * 1e9).toFixed(0),
          //stateInit: body.toBoc().toString('base64'),
          // payload: responseData.id,
          payload: body.toBoc().toString('base64'),
        },
      ],
    };

    try {
      const result = await tonConnectUI.sendTransaction(myTransaction);

      const examplePromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(200);
          dispatch(getMyCart([]));
        }, 2000);
      });

      const bocCell = TonWeb.boc.Cell.oneFromBoc(
        TonWeb.utils.base64ToBytes(result.boc)
      );

      const hash = TonWeb.utils.bytesToBase64(await bocCell.hash());

      // Display the loading toast until the promise is either resolved or rejected
      toast.promise(examplePromise, {
        success: {
          title: 'Transaction successful',
          description: 'Transaction was successful',
        },
        error: {
          title: 'Transaction rejected',
          description: 'Transaction failed',
        },
        loading: { title: 'Transaction pending', description: 'Please wait' },
      });

      await sendMessageWithButton(
        chatInfo.id,
        `Thank you for ordering from ${storeState?.store?.store_name}. Your payment payment number is ${responseData.id}.`
      );

      dispatch(sendBocAction({ boc: hash, payment_id: responseData.id }));

      navigate(`/payment/${id}`);
    } catch (e) {
      console.error(e);
    }

    // window.Telegram.WebApp.close();
  };

  const handleCheckout = () => {
    // setSubmitting(true);
    const products = cartState.myCart.map(item => ({
      product_id: item.id,
      product_outlet_type: 'store',
      product_outlet_id: item?.store_id,
      quantity: item.count ?? 1,
    }));
    if (authState?.token) {
      const callback = responseData => {
        const callBackBoc = responseData => {
          if (responseData.id) {
            pay(responseData);
          }

          return;
        };
        dispatch(
          payoutCart({
            user_id: authState.user?.id,
            ...state,
            payment_method: 'cello',
            payment_type: 'standard',
            recipient_address: state?.address ?? 'no current address',
            callBackBoc,
          })
        );
        // pay();
        return;
      };
      dispatch(
        userCheckout({ products, user_id: authState.user?.id, callback })
      );
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() =>
                navigate(`/checkout/${id}`, {
                  state: state,
                })
              }
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>PAYMENT METHOD</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Box
          px={{ base: '20px', md: '20px' }}
          pb={{ base: '20px', md: '20px' }}
          flexDirection={'column'}
        >
          <Text
            fontSize={'14px'}
            lineHeight={'19.6px'}
            fontWeight={'400'}
            mb={6}
          >
            Payments might take more than 15 minutes to process if the network
            is congested !!!.
          </Text>

          <Card
            mb={3}
            p={4}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
            onClick={() => {
              tonConnectUI.disconnect();
              setTimeout(() => {
                open();
              }, 2000);
            }}
          >
            <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
              <Image src={WalletSvg} mr={3} />

              <Text
                noOfLines={1}
                fontSize={'12px'}
                lineHeight={'18px'}
                width={'90%'}
                fontWeight={'500'}
                color={'rgba(36, 107, 253, 1)'}
              >
                {wallet?.account?.address
                  ? autoEllipses(wallet?.account?.address, 600)
                  : 'Connect to pay'}
              </Text>
            </Box>
          </Card>

          <Text
            color={'rgba(108, 117, 125, 1)'}
            fontSize={'10px'}
            lineHeight={'12px'}
            fontWeight={'400'}
          >
            Effortlessly pay by linking or changing your connected wallet for
            quick checkout and instantly earn points!
          </Text>

          {wallet?.account?.address && (
            <>
              <Text
                mt={10}
                mb={2}
                fontSize={'20px'}
                lineHeight={'26.04px'}
                fontWeight={'700'}
              >
                Payment Method
              </Text>

              <Text
                mb={4}
                fontSize={'10px'}
                lineHeight={'12px'}
                fontWeight={'400'}
                color={'rgba(108, 117, 125, 1)'}
              >
                "Choose your preferred payment method to complete your purchase
                now!"
              </Text>

              <Card>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Flex
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      width={'100%'}
                      onClick={() => handleCheckout()}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <Image src={TonSvg} mr={3} />
                        <Heading
                          size="xs"
                          color={
                            colorMode == 'light'
                              ? 'rgba(52, 75, 103, 1)'
                              : 'white'
                          }
                          textTransform="uppercase"
                        >
                          Pay with TON
                        </Heading>
                      </Box>
                    </Flex>
                  </Stack>
                </CardBody>
              </Card>
            </>
          )}

          <Center flexDirection={'column'} mt={10}>
            <Text
              fontSize={'10px'}
              lineHeight={'12px'}
              fontWeight={'700'}
              textAlign={'center'}
            >
              "By proceeding to payment, you acknowledge and accept our{' '}
            </Text>
            <Text
              color={'rgba(36, 107, 253, 1)'}
              fontSize={'10px'}
              lineHeight={'12px'}
              fontWeight={'700'}
              textAlign={'center'}
              onClick={() => navigate(`/terms/${id}`)}
            >
              Terms and Conditions."
            </Text>
          </Center>
        </Box>
      </Flex>
    </>
  );
}
