import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Image,
  Stack,
  StackDivider,
  Button,
  Center,
  Flex,
  Card,
  CardBody,
  Spacer,
  useColorMode,
  useColorModeValue,
  Textarea,
  useToast,
  Heading,
} from '@chakra-ui/react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { BiCopy } from 'react-icons/bi';

import UploadSvg from '../assets/svg/upload.svg';
import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { savedForm, toggleDarkMode } from '../redux/slices/settings';

import { formatDate, formatNumberWithCommas } from '../helpers/helpers';
import { verifyPayment } from '../redux/slices/payments';

export default function CustomerOrderDetails() {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const paymentState = useSelector(state => state.payment);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(paymentState?.payment?.id);

    toast({
      position: 'top-right',
      render: () => (
        <Box color="white" p={3} bg="blue.500">
          Copied
        </Box>
      ),
    });
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
        pb={{ base: '90px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() =>
                id ? navigate(`/profile/${id}`) : navigate(`/store-profile`)
              }
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>PAYMENT DETAILS</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Box px={{ base: '20px', md: '20px' }}>
          <Heading
            fontWeight={'600'}
            fontSize={'16px'}
            lineHeight={'18px'}
            my={2}
          >
            Product Details
          </Heading>
          <Card p={4}>
            {(paymentState?.payment?.store_order ?? []).length > 0 &&
              paymentState?.payment?.store_order.map((order, i) => (
                <React.Fragment>
                  {(order?.order_items ?? []).length > 0 &&
                    order?.order_items.map((item, key) => (
                      <Box
                        key={key}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        mb={2}
                      >
                        <Text fontSize={'16px'} fontWeight={'400'}>
                          {item?.quantity}x {item?.product_name}
                        </Text>

                        <Text fontSize={'16px'} fontWeight={'600'}>
                          ${item?.product_amount}
                        </Text>
                      </Box>
                    ))}
                </React.Fragment>
              ))}
          </Card>

          <Heading
            fontWeight={'600'}
            fontSize={'16px'}
            lineHeight={'18px'}
            mb={2}
            mt={8}
          >
            Payment Details
          </Heading>
          <Card p={4}>
            {(paymentState?.payment?.store_order ?? []).length > 0 &&
              paymentState?.payment?.store_order.map((order, i) => (
                <React.Fragment key={i}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Date
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {formatDate(order?.created_at)}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Time
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {new Date(order?.created_at).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                      })}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Currency
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {order?.order_currency ?? 'USD'}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Delivery Fee
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {formatNumberWithCommas(order?.order_delivery_fee)}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Discount
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {formatNumberWithCommas(order?.order_discount)}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Insurance Fee
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {formatNumberWithCommas(order?.order_insurance_fee)}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Tax Fee
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {formatNumberWithCommas(order?.order_tax_fee)}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Sub Amount
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {formatNumberWithCommas(order?.order_sub_amount)}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Total
                    </Text>

                    <Text fontSize={'16px'} fontWeight={'600'}>
                      {formatNumberWithCommas(order?.order_amount)}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    mb={2}
                  >
                    <Text fontSize={'16px'} fontWeight={'400'}>
                      Payment ID
                    </Text>

                    <Flex
                      onClick={() => handleCopy()}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Text noOfLines={1} fontSize={'16px'} fontWeight={'600'}>
                        {paymentState?.payment?.id.slice(0, 15) + '....'}
                      </Text>

                      <BiCopy size={16} color="rgba(52, 75, 103, 1)" />
                    </Flex>
                  </Box>
                </React.Fragment>
              ))}
          </Card>

          {paymentState?.payment?.status !== 'success' &&
            paymentState?.payment?.status !== 'pending' && (
              <Center
                flexDirection={'column'}
                display={'flex'}
                my={'15px'}
                width={'100%'}
              >
                <Text
                  textAlign={'center'}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                >
                  Your payment for this order wasn’t successful, verify or
                  ignore.
                </Text>

                <Button
                  mt={4}
                  alignSelf={'center'}
                  borderRadius={'16px'}
                  width={'80%'}
                  height={'54px'}
                  backgroundColor={'rgba(36, 107, 253, 1)'}
                  isLoading={paymentState?.isLoading}
                  isDisabled={!paymentState?.payment?.id}
                  padding={2}
                  onClick={() =>
                    dispatch(verifyPayment({ id: paymentState?.payment?.id }))
                  }
                >
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="26px"
                    color={'white'}
                  >
                    Verify Payment
                  </Text>
                </Button>
              </Center>
            )}
        </Box>
      </Flex>
    </>
  );
}
