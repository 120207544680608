import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Switch,
  Heading,
  Avatar,
  Image,
  useColorMode,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';
import auth, { login } from '../redux/slices/auth';
import { getMyPayment, showAPayment } from '../redux/slices/payments';

import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
  generateRandomAlphanumericName,
  getDateWithTime,
  ucfirst,
} from '../helpers/helpers';

export default function Profile() {
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('white', 'gray.800');

  let chatInfo = window.Telegram.WebApp.initDataUnsafe.user;

  const authState = useSelector(state => state.auth);

  const paymentState = useSelector(state => state.payment);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);

  const { id } = useParams();

  const getUpdateUserToken = () => {
    if (!chatInfo?.id) {
      return { email: '', password: '', name: '' };
    }
    const input = chatInfo?.id + '-' + chatInfo?.username;

    const result = input.split(/-/);

    //const result = ['2037125694', 'Raphael'];

    const email = 'bot' + result[0] + '@telegram.com';

    const password = result[0] + 'botPass';

    const name = result[1] ? result[1] : generateRandomAlphanumericName();

    return { email, password, name };
  };

  React.useEffect(() => {
    handleLogin();

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleLogin = () => {
    const { email, password, name } = getUpdateUserToken();

    dispatch(login({ email, password }));
  };

  React.useEffect(() => {
    if (authState?.token) {
      dispatch(getMyPayment());
    }
  }, [authState.token]);

  const showOrder = payment_id => {
    dispatch(showAPayment({ id: payment_id }));
    id
      ? navigate(`/customer-order-details/${id}`)
      : navigate(`/store-order-details`);
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() =>
                id ? navigate(`/store/${id}`) : navigate(`/dashboard`)
              }
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>PROFILE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Center flexDirection={'column'}>
          <Avatar size="xl" src="https://bit.ly/broken-link" />

          <Text
            fontSize={'24px'}
            lineHeight={'36px'}
            mt={'20px'}
            mb={'5px'}
            fontWeight={'400'}
          >
            {chatInfo?.username ?? '--'}
          </Text>

          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            direction="row"
            mb={'40px'}
          >
            <Box>
              <Text
                fontSize={'16px'}
                textAlign={'center'}
                lineHeight={'34px'}
                fontWeight={'400'}
              >
                {authState?.user?.points
                  ? formatNumberWithCommas(authState?.user?.points)
                  : '0'}
              </Text>

              <Text
                fontSize={'14px'}
                lineHeight={'23px'}
                fontWeight={'400'}
                textAlign={'center'}
                color={'rgba(155, 155, 155, 1)'}
              >
                Not Points
              </Text>
            </Box>
            <Divider
              mx={'20px'}
              mt={'5px'}
              alignSelf={'center'}
              justifyContent={'center'}
              height={'35px'}
              orientation="vertical"
            />
            <Box>
              <Text
                textAlign={'center'}
                fontSize={'16px'}
                lineHeight={'34px'}
                fontWeight={'400'}
              >
                {authState?.user?.level ? authState?.user?.level : '0'}
              </Text>

              <Text
                fontSize={'14px'}
                lineHeight={'23px'}
                fontWeight={'400'}
                textAlign={'center'}
                color={'rgba(155, 155, 155, 1)'}
              >
                Current Level
              </Text>
            </Box>
          </Flex>

          {paymentState?.myPayments &&
            paymentState?.myPayments?.data &&
            paymentState?.myPayments?.data.length > 0 && (
              <Box
                borderBottomColor={'rgba(36, 107, 253, 1)'}
                borderBottomWidth={'4px'}
                borderBottomLeftRadius={'2px'}
                borderBottomRightRadius={'2px'}
                paddingBottom={'5px'}
                marginRight={'20px'}
                mb={5}
              >
                <Text
                  color={'rgba(36, 107, 253, 1)'}
                  fontWeight={'500'}
                  fontSize={'15px'}
                >
                  {'Recent Activity'}
                </Text>
              </Box>
            )}

          {isLoading ? (
            <Skeleton
              noOfLines={1}
              mb={2}
              spacing="4"
              height={'50px'}
              width={'90%'}
              borderRadius={'5px'}
            />
          ) : (
            <Flex
              alignItems={'center'}
              flexDirection={'column'}
              maxH={'50vh'}
              width={'100%'}
              p={0}
              m={0}
              overflowY={'auto'}
            >
              {paymentState?.myPayments &&
                paymentState?.myPayments?.data &&
                paymentState?.myPayments?.data.length > 0 &&
                paymentState?.myPayments.data.map((item, index) => (
                  <Card
                    key={index}
                    width={'90%'}
                    py={4}
                    px={5}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mb={3}
                    onClick={() => showOrder(item.id)}
                  >
                    <Text
                      fontSize={'43px'}
                      lineHeight={'40px'}
                      fontWeight={'400'}
                      color={
                        item?.status == 'success'
                          ? 'rgba(0, 210, 32, 1)'
                          : item?.status == 'pending'
                          ? 'rgba(255, 169, 39, 1)'
                          : 'rgba(229, 20, 0, 1)'
                      }
                    >
                      {getDateWithTime(item?.created_at).hours12}
                    </Text>
                    <Flex alignItems={'center'} direction={'column'}>
                      <Text
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={'400'}
                        color={
                          item?.status == 'success'
                            ? 'rgba(0, 210, 32, 1)'
                            : item?.status == 'pending'
                            ? 'rgba(255, 169, 39, 1)'
                            : 'rgba(229, 20, 0, 1)'
                        }
                      >
                        {getDateWithTime(item?.created_at).minutes}
                      </Text>
                      <Text
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={'400'}
                        color={'rgba(142, 139, 139, 0.8)'}
                      >
                        {getDateWithTime(item?.created_at).ampm}
                      </Text>
                    </Flex>

                    <Flex direction={'column'}>
                      <Text
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        fontWeight={'400'}
                      >
                        {formatDate(item?.created_at)}
                      </Text>

                      <Text
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        fontWeight={'400'}
                        color={'rgba(142, 139, 139, 0.8)'}
                      >
                        {autoEllipses(authState?.user?.email)}
                      </Text>
                    </Flex>

                    <Text
                      fontSize={'14px'}
                      lineHeight={'18px'}
                      fontWeight={'400'}
                    >
                      {formatNumberWithCommas(item?.amount)}{' '}
                      <span style={{ color: 'rgba(142, 139, 139, 0.8)' }}>
                        {item?.currency ?? 'USD'}
                      </span>
                    </Text>
                  </Card>
                ))}
            </Flex>
          )}
        </Center>
      </Flex>
    </>
  );
}
