import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Image,
  Stack,
  StackDivider,
  Button,
  Flex,
  Card,
  CardBody,
  Spacer,
  useColorMode,
  useColorModeValue,
  Textarea,
  useToast,
  Heading,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';
import { BiCopy } from 'react-icons/bi';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { savedForm, toggleDarkMode } from '../redux/slices/settings';

import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
} from '../helpers/helpers';

export default function WithdrawalDetails() {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('black', 'white');

  const buttonText = useColorModeValue('white', 'black');

  const bg = useColorModeValue('black', 'white');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const startApp = query.get('startapp');

  const withdrawState = useSelector(state => state.withdraw?.transaction);
  const settingsState = useSelector(state => state.settings);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(withdrawState?.reference_id);

    toast({
      position: 'top-right',
      render: () => (
        <Box color="white" p={3} bg="blue.500">
          Copied
        </Box>
      ),
    });
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/all-withdrawals`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>WITHDRAWAL REQUEST</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Box px={{ base: '20px', md: '20px' }}>
          <Heading
            fontWeight={'600'}
            fontSize={'16px'}
            lineHeight={'18px'}
            mb={4}
          >
            Withdrawal Details
          </Heading>
          <Card p={4}>
            <React.Fragment>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Date
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatDate(new Date(withdrawState?.created_at))}
                </Text>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Time
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {new Date(withdrawState?.created_at).toLocaleTimeString(
                    'en-US',
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true,
                    }
                  )}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Currency
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {settingsState?.applicationSettings?.platform_currency
                    ? settingsState?.applicationSettings?.platform_currency
                        ?.alphabetic_code
                    : 'USD'}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Status
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {withdrawState?.status && withdrawState?.status.toUpperCase()}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Service Fee
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas(withdrawState?.amount)}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Transaction ID
                </Text>

                <Flex
                  onClick={() => handleCopy()}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Text noOfLines={1} fontSize={'16px'} fontWeight={'600'}>
                    {autoEllipses(withdrawState?.reference_id)}
                  </Text>
                  <BiCopy size={16} color="rgba(52, 75, 103, 1)" />
                </Flex>
              </Box>
            </React.Fragment>
          </Card>

          {withdrawState?.status !== 'completed' && (
            <Text
              textAlign={'center'}
              fontSize={'20px'}
              lineHeight={'30px'}
              fontWeight={'400'}
              my={'30px'}
            >
              "Your withdrawal request is being processed and will be completed
              within approximately 48 hours."
            </Text>
          )}
        </Box>
      </Flex>
    </>
  );
}
