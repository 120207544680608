import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Image,
  Stack,
  StackDivider,
  Button,
  Center,
  Flex,
  Card,
  CardBody,
  Spacer,
  useColorMode,
  useColorModeValue,
  Textarea,
  useToast,
  Heading,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import ClockSvg from '../assets/svg/clock.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { savedForm, toggleDarkMode } from '../redux/slices/settings';

import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
} from '../helpers/helpers';

export default function FinalizeWithdrawal() {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/all-withdrawals`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>WITHDRAWAL</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Center flexDirection={'column'} px={{ base: '20px', md: '20px' }}>
          <Heading
            fontWeight={'700'}
            fontSize={'20px'}
            lineHeight={'26.04px'}
            mb={4}
          >
            Withdrawal Confirmation
          </Heading>
          <Text
            mb={'4px'}
            fontSize={'10px'}
            fontWeight={'400'}
            lineHeight={'12px'}
          >
            "Kindly verify withdrawal details and amount to proceed !"
          </Text>

          <Image src={ClockSvg} my={10} />

          <Card width={'100%'} p={4}>
            <React.Fragment>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Date
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatDate(new Date())}
                </Text>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Time
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {new Date().toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                  })}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Amount
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas('20000')}
                </Text>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={2}
              >
                <Text fontSize={'16px'} fontWeight={'400'}>
                  Network Fee
                </Text>

                <Text fontSize={'16px'} fontWeight={'600'}>
                  {formatNumberWithCommas('200')}
                </Text>
              </Box>
            </React.Fragment>
          </Card>

          <Button
            mt={20}
            alignSelf={'center'}
            borderRadius={'16px'}
            width={'100%'}
            height={'54px'}
            backgroundColor={'rgba(36, 107, 253, 1)'}
            padding={2}
          >
            <Text
              fontSize="16px"
              fontWeight="400"
              lineHeight="26px"
              color={'white'}
            >
              Withdraw Funds
            </Text>
          </Button>
        </Center>
      </Flex>
    </>
  );
}
