import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Divider,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  CardFooter,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Switch,
  Heading,
  Image,
  useColorMode,
  useColorModeValue,
  SkeletonText,
  Skeleton,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import ArrowSvg from '../assets/svg/arrow-left.svg';
import EmptyCategorySvg from '../assets/svg/empty-category.svg';

import bot from '../assets/img/bot.jpeg';
import money from '../assets/img/money_wallpaper.jpeg';
import humanity from '../assets/img/wallpaper.png';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../redux/slices/auth';
import {
  approveProductStatus,
  deleteProduct,
  getMyProducts,
  singleProduct,
  unApproveProductStatus,
} from '../redux/slices/product';
import {
  getApplicationSettings,
  toggleDarkMode,
} from '../redux/slices/settings';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { ucfirst } from '../helpers/helpers';

export default function AllProducts() {
  const { colorMode, toggleColorMode } = useColorMode();

  const text = useColorModeValue('white', 'gray.800');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  const productState = useSelector(state => state.product);

  const settingsState = useSelector(state => state.settings);

  const authState = useSelector(state => state.auth);

  const [isLoading, setIsLoading] = React.useState(true);

  const [foodMenu, setFoodMenu] = React.useState([]);
  const [activeFoodMenu, setActiveFoodMenu] = React.useState('');

  React.useEffect(() => {
    if (authState.token) {
      dispatch(getMyProducts());
    } else {
      navigate('/dashboard');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [authState.token, productState?.reload]);

  React.useEffect(() => {
    dispatch(getApplicationSettings());
  }, []);

  React.useEffect(() => {
    // Get food menu to state

    if (!isLoading && productState?.myProducts.length == 0) {
      navigate('/upload-product');
      return;
    }

    if (productState?.myProducts && productState?.myProducts.length > 0) {
      let menuData = [];
      for (let b = 0; b < productState?.myProducts.length; b++) {
        const element = productState?.myProducts[b];
        const foodCategories = element?.merchandise_categories ?? [];
        for (let c = 0; c < foodCategories.length; c++) {
          if (menuData.length == 0 && c == 0) {
            menuData.push({
              name: 'All Products',
              data: productState?.myProducts,
              active: true,
            });
          }
          const category = foodCategories[c];
          const alignFood = productState?.myProducts.filter(a =>
            (a?.merchandise_categories ?? []).includes(category)
          );
          let newData = menuData.find(a => a.name === category);
          if (!newData) {
            menuData.push({
              name: category,
              data: alignFood,
              active: false,
            });
          }
        }
      }
      setFoodMenu(menuData);
      setActiveFoodMenu(menuData[0]);
    }
  }, [productState?.myProducts]);

  const onDeleteProduct = id => {
    dispatch(
      deleteProduct({
        id: id,
      })
    );
  };

  const approveProduct = id => {
    dispatch(
      approveProductStatus({
        id: id,
      })
    );
  };

  const unApproveProduct = id => {
    dispatch(
      unApproveProductStatus({
        id: id,
      })
    );
  };

  const showProduct = item => {
    dispatch(singleProduct(item));
    navigate(`/edit-product`);
  };

  return (
    <>
      <Flex
        flexDirection="column"
        bgColor={colorMode == 'light' && 'rgba(238, 238, 238, 0.2)'}
        // height={'100vh'}
        // pt={{ base: '120px', md: '75px' }}
        pb={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={10}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>PRODUCTS</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex
          px={{ base: '20px', md: '20px' }}
          mb={{ base: '20px', md: '20px' }}
          direction={'row'}
          overflowX="auto"
          maxWidth="100%"
        >
          {foodMenu &&
            foodMenu.length > 0 &&
            foodMenu?.map((merch, i) => (
              <Box
                key={i}
                borderBottomColor={
                  merch?.name === activeFoodMenu?.name
                    ? 'rgba(36, 107, 253, 1)'
                    : 'transparent'
                }
                borderBottomWidth={'4px'}
                borderBottomLeftRadius={'2px'}
                borderBottomRightRadius={'2px'}
                paddingBottom={'5px'}
                marginRight={'20px'}
                onClick={() => setActiveFoodMenu(merch)}
              >
                <Text
                  color={
                    merch?.name === activeFoodMenu?.name
                      ? 'rgba(36, 107, 253, 1)'
                      : 'rgba(155, 155, 155, 1)'
                  }
                  fontWeight={'500'}
                  whiteSpace={'nowrap'}
                  fontSize={'15px'}
                >
                  {ucfirst(merch?.name)}
                </Text>
              </Box>
            ))}
        </Flex>

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          }} // 1 column on mobile, 3 columns on medium screens and above
          gap={{ base: 4, md: 8 }}
          px={{ base: '20px', md: '20px' }}
        >
          {isLoading ? (
            <Flex direction={'row'}>
              <Skeleton
                height="194px"
                width="172px"
                marginRight="10px"
                borderRadius="lg"
              />
              <Box>
                <SkeletonText noOfLines={1} mb={2} spacing="4" width="150px" />
                <SkeletonText
                  noOfLines={1}
                  spacing="4"
                  width="100px"
                  marginBottom="2"
                />
                <SkeletonText noOfLines={7} spacing="4" />
              </Box>
            </Flex>
          ) : (
            activeFoodMenu &&
            activeFoodMenu?.data.map((merchandise, key) => (
              <Box
                maxW="sm"
                key={key}
                borderStyle={'none'}
                display={'flex'}
                flexDirection={'row'}
              >
                <Image
                  src={merchandise?.merchandise_pictures[0]}
                  alt={merchandise?.merchandise_name}
                  height={'194px'}
                  marginRight={'10px'}
                  minW={'172px'}
                  maxW={'172px'}
                  borderRadius="lg"
                  onClick={() => showProduct(merchandise)}
                />

                <Flex
                  width={'100%'}
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                >
                  <Box
                    data-testid="merchandise-box"
                    onClick={() => showProduct(merchandise)}
                  >
                    <Text
                      lineHeight={'15px'}
                      fontSize={'15px'}
                      mb={3}
                      fontWeight={'800'}
                    >
                      {merchandise?.merchandise_name}
                    </Text>
                    <Heading
                      mb={3}
                      size={'sm'}
                      fontWeight={'500'}
                      lineHeight={'15px'}
                      fontSize={'15px'}
                    >
                      {merchandise?.merchandise_amount}

                      <span
                        style={{
                          marginLeft: '5px',
                          color: 'rgba(142, 139, 139, 0.8)',
                        }}
                      >
                        {settingsState?.applicationSettings?.platform_currency
                          ? settingsState?.applicationSettings
                              ?.platform_currency?.alphabetic_code
                          : 'USD'}
                      </span>
                    </Heading>

                    <Text
                      fontSize={'15px'}
                      lineHeight={'17px'}
                      fontWeight={'400'}
                      noOfLines={2}
                    >
                      {merchandise?.merchandise_description}
                    </Text>
                  </Box>

                  <Box>
                    <Flex
                      alignItems={'center'}
                      direction={'row'}
                      justifyContent={'space-between'}
                    >
                      <Text
                        fontSize={'15px'}
                        lineHeight={'18px'}
                        fontWeight={'400'}
                        color={'rgba(142, 139, 139, 0.7)'}
                      >
                        Availability
                      </Text>

                      <Switch
                        colorScheme="green"
                        isChecked={merchandise?.business_approved}
                        onChange={() => {
                          if (merchandise?.business_approved) {
                            unApproveProduct(merchandise?.id);
                            return;
                          }
                          approveProduct(merchandise?.id);
                        }}
                        size={'lg'}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            ))
          )}
        </Grid>
        {!isLoading && !activeFoodMenu && (
          <Center
            mt={'20vh'}
            px={{ base: '20px', md: '20px' }}
            flexDirection={'column'}
          >
            <Image src={EmptyCategorySvg} />

            <Text
              fontSize={'16px'}
              lineHeight={'24px'}
              fontWeight={'400'}
              mt={6}
              textAlign={'center'}
            >
              Seems like you don't have any products yet.
            </Text>
            <Text
              fontSize={'16px'}
              lineHeight={'24px'}
              fontWeight={'400'}
              mt={6}
              textAlign={'center'}
              onClick={() => navigate(`/upload-product`)}
              color={'rgba(36, 107, 253, 1)'}
            >
              Add Products
            </Text>
          </Center>
        )}
      </Flex>
    </>
  );
}
