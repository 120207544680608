import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  UnorderedList,
  ListItem,
  Divider,
  Heading,
  Avatar,
  Image,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ArrowSvg from '../assets/svg/arrow-left.svg';

import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import DropdownArrowSvg from '../assets/svg/dropdown-arrow.svg';

import DropupArrowSvg from '../assets/svg/dropup-arrow.svg';

import BlackDropdownArrowSvg from '../assets/svg/blackdropdown.svg';

import BlackDropupArrowSvg from '../assets/svg/blackdropup.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { toggleDarkMode } from '../redux/slices/settings';
import { Player } from '@lottiefiles/react-lottie-player';

export default function TermsConditionsPage() {
  const { colorMode, toggleColorMode } = useColorMode();

  const { state } = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        pb={{ base: '90px', md: '20px' }}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
          mb={7}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() =>
                id
                  ? navigate(`/payment-method/${id}`, {
                      state: state,
                    })
                  : navigate('/create-store')
              }
              variant="ghost"
              alignSelf={'flex-start'}
            >
              <Image
                src={colorMode == 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize={'16px'}>Notstore Terms and Conditions</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex direction={'column'} px={{ base: '20px', md: '20px' }}>
          <Heading as="h1" size="lg" mb={4}>
            Terms and Conditions
          </Heading>

          <Heading as="h2" size="md" mt={6} mb={2}>
            1. Introduction
          </Heading>
          <Text mb={4}>
            Notstore is a Telegram bot that allows users to create a store, list
            products, and share their store links on social platforms to attract
            buyers. Users can receive payments through their Telegram wallet
            using cryptocurrency. By using Notstore, you agree to the following
            terms and conditions.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            2. User Eligibility
          </Heading>
          <Text mb={4}>
            To use Notstore, you must be at least 18 years old and comply with
            all local, national, and international laws regarding online sales
            and cryptocurrency transactions.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            3. Account and Store Creation
          </Heading>
          <Text mb={4}>
            Users can create a store and list items for sale on Notstore without
            the need for registration. You are responsible for ensuring that the
            information provided in your store is accurate, complete, and up to
            date.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            4. Permitted Products
          </Heading>
          <Text mb={4}>
            Notstore permits the sale of a wide range of products, provided they
            are legal and comply with Telegram’s community guidelines. The
            following items are prohibited:
          </Text>
          <UnorderedList mb={4}>
            <ListItem>Illegal drugs or substances</ListItem>
            <ListItem>Weapons, firearms, or explosives</ListItem>
            <ListItem>Counterfeit goods or pirated content</ListItem>
            <ListItem>Offensive, discriminatory, or obscene materials</ListItem>
            <ListItem>
              Items violating any intellectual property rights
            </ListItem>
          </UnorderedList>
          <Text mb={4}>
            Notstore reserves the right to remove any listing that violates
            these terms or is deemed inappropriate.
          </Text>

          <Divider my={6} />

          <Heading as="h2" size="md" mt={6} mb={2}>
            5. Payment and Transactions
          </Heading>
          <Text mb={4}>
            All payments on Notstore are facilitated through Telegram’s wallet
            system, which supports cryptocurrency payments. Users are
            responsible for setting up their wallets and ensuring the security
            of their funds. Notstore is not responsible for any payment
            disputes, lost funds, or failed transactions. Transactions are
            final, and refunds or chargebacks are not supported unless both
            parties agree to a resolution.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            6. Fees and Charges
          </Heading>
          <Text mb={4}>
            Notstore is free to use for basic store creation and product
            listing. However, certain premium features may incur subscription
            fees or transaction-based charges. These fees will be clearly
            outlined before any charge is applied.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            7. Store and Product Visibility
          </Heading>
          <Text mb={4}>
            Notstore allows users to share their store links across social media
            and other channels. We do not guarantee any specific level of
            exposure or sales. Store owners are responsible for promoting their
            own stores and driving traffic to their products.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            8. User Conduct
          </Heading>
          <Text mb={4}>
            Users are expected to conduct themselves professionally and
            responsibly when using Notstore. Harassment, spam, or misleading
            actions will not be tolerated. Violation of these terms can result
            in account suspension or termination.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            9. Intellectual Property
          </Heading>
          <Text mb={4}>
            By listing products on Notstore, users confirm that they have the
            rights to sell the items and that the listings do not infringe on
            any third-party rights. Notstore is not liable for intellectual
            property disputes between users and third parties.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            10. Limitation of Liability
          </Heading>
          <Text mb={4}>
            Notstore provides a platform for facilitating transactions but does
            not guarantee the quality, safety, legality, or availability of
            items listed. We are not responsible for any disputes, damages, or
            losses resulting from transactions made through the platform.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            11. Privacy Policy
          </Heading>
          <Text mb={4}>
            Notstore respects your privacy and only collects minimal data
            necessary to run the platform, including Telegram user IDs, store
            details, and payment transaction logs. We do not share your personal
            data with third parties except when required by law or to facilitate
            transactions.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            12. Modifications to Terms
          </Heading>
          <Text mb={4}>
            Notstore reserves the right to modify these terms and conditions at
            any time. Changes will be communicated to users, and continued use
            of the platform constitutes acceptance of the new terms.
          </Text>

          <Divider my={6} />

          <Heading as="h1" size="lg" mb={4}>
            Privacy Policy
          </Heading>

          <Heading as="h2" size="md" mt={6} mb={2}>
            1. What We Collect
          </Heading>
          <UnorderedList mb={4}>
            <ListItem>Your Telegram username and ID.</ListItem>
            <ListItem>The store and product info you provide.</ListItem>
            <ListItem>
              Basic transaction details (no, we don’t ask for your private
              wallet keys).
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="md" mt={6} mb={2}>
            2. How We Use Your Info
          </Heading>
          <UnorderedList mb={4}>
            <ListItem>To keep Notstore running smoothly.</ListItem>
            <ListItem>To help with payments and sales.</ListItem>
            <ListItem>
              To give you support when needed (but don’t expect us to babysit).
            </ListItem>
          </UnorderedList>

          <Heading as="h2" size="md" mt={6} mb={2}>
            3. Sharing Your Info
          </Heading>
          <Text mb={4}>
            We don’t sell your data to anyone. The only time we share is if the
            law requires it or we need to protect the safety and rights of other
            users.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            4. Security
          </Heading>
          <Text mb={4}>
            We use standard security measures to protect your info. While we
            work to keep things safe, no system is perfect, so we can’t promise
            100% security.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            User Guidelines
          </Heading>

          <Heading as="h2" size="md" mt={6} mb={2}>
            1. Listing Items
          </Heading>
          <UnorderedList mb={4}>
            <ListItem>
              Be clear and honest about what you’re selling. No tricks.
            </ListItem>
            <ListItem>Don’t list anything illegal or harmful.</ListItem>
            <ListItem>Make sure you own what you’re selling.</ListItem>
          </UnorderedList>

          <Heading as="h2" size="md" mt={6} mb={2}>
            2. Talking to Buyers
          </Heading>
          <UnorderedList mb={4}>
            <ListItem>Be polite and clear when dealing with buyers.</ListItem>
            <ListItem>Don’t spam or harass people, or you’re out.</ListItem>
          </UnorderedList>

          <Heading as="h2" size="md" mt={6} mb={2}>
            3. Handling Sales
          </Heading>
          <Text mb={4}>
            You’re responsible for delivering anything you sell, including
            shipping and answering buyer questions.
          </Text>

          <Heading as="h2" size="md" mt={6} mb={2}>
            Policy Changes
          </Heading>
          <Text mb={4}>
            We might update these policies whenever we need to. If anything
            major changes, we’ll let you know through the bot.
          </Text>
          <Text mb={4}>
            By using Notstore, you agree to any new terms. If that doesn’t work
            for you, well, you know what to do.
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
