import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getMyProducts = createAsyncThunk(
  'product/getMyProducts',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/merchandise/me`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response?.data?.data;
    }

    return {};
  }
);

export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/merchandise/store`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const response = await res.json();

    if (response?.status_code == 201) {
      return response;
    }

    const errorMsg = response.errors
      ? Object.values(response.errors)[0][0]
      : 'An error occurred creating product';

    return thunkAPI.rejectWithValue(errorMsg);
  }
);

export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/merchandise/update`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response;
    }

    return thunkAPI.rejectWithValue(response);
  }
);

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/merchandise/delete`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const response = await res.json();

    return response;
  }
);

export const approveProductStatus = createAsyncThunk(
  'product/approveProductStatus',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/merchandise/approve/business`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response;
    }

    return thunkAPI.rejectWithValue('Could not approve product');
  }
);

export const unApproveProductStatus = createAsyncThunk(
  'product/unApproveProductStatus',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    const res = await fetch(`${apiUrl}/merchandise/unapprove/business`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      return response;
    }

    return thunkAPI.rejectWithValue('Could not unapprove product');
  }
);

export const filterStoreProduct = createAsyncThunk(
  'product/filterStoreProduct',
  async (data, thunkAPI) => {
    const res = await fetch(`${apiUrl}/public/merchandise/filter/index`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const response = await res.json();

    return response?.data;
  }
);

export const singleProduct = createAsyncThunk(
  'product/singleProduct',
  async (data, thunkAPI) => {
    return data;
  }
);

export const resetCreated = createAsyncThunk(
  'product/resetCreated',
  async (data, thunkAPI) => {
    return data;
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    myProducts: [],
    storeProducts: [],
    singleProduct: {},
    isLoading: false,
    reload: false,
    created: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMyProducts.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyProducts.fulfilled, (state, action) => {
      state.isLoading = false;

      state.myProducts = action.payload;
    });
    builder.addCase(getMyProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(createProduct.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createProduct.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.created = true;
      state.reload = !state.reload;
      state.error = '';
    });
    builder.addCase(createProduct.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteProduct.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reload = !state.reload;
    });
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(approveProductStatus.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(approveProductStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reload = !state.reload;
      if (state.singleProduct && Object.keys(state.singleProduct).length > 0) {
        state.singleProduct = {
          ...state.singleProduct,
          business_approved: true,
        };
      }
    });
    builder.addCase(approveProductStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(unApproveProductStatus.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(unApproveProductStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reload = !state.reload;
      if (state.singleProduct && Object.keys(state.singleProduct).length > 0) {
        state.singleProduct = {
          ...state.singleProduct,
          business_approved: false,
        };
      }
    });
    builder.addCase(unApproveProductStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(filterStoreProduct.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(filterStoreProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.storeProducts = action.payload;
    });
    builder.addCase(filterStoreProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(singleProduct.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(singleProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleProduct = action.payload;
    });
    builder.addCase(singleProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(resetCreated.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(resetCreated.fulfilled, (state, action) => {
      state.isLoading = false;
      state.created = false;
    });
    builder.addCase(resetCreated.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateProduct.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reload = !state.reload;
      state.error = '';
    });
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default productSlice.reducer;
