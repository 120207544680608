import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const withdrawFromWallet = createAsyncThunk(
  'withdrawal/withdrawFromWallet',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    let callback = () => {};

    if (data?.callback) {
      callback = data.callback;

      delete data.callback;
    }

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/wallet-transaction/withdraw`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    });
    const response = await res.json();

    if (response?.status_code == 200) {
      callback(response.data);
      return response?.data;
    }

    const errorMsg = response.message
      ? response?.message
      : 'An error occurred making withdrawal';

    return thunkAPI.rejectWithValue(errorMsg);
  }
);

export const singleTransaction = createAsyncThunk(
  'order/singleTransaction',
  async (data, thunkAPI) => {
    return data;
  }
);

export const getWalletTransactions = createAsyncThunk(
  'withdrawal/getWalletTransactions',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    const res = await fetch(
      `${apiUrl}/wallet-transaction/index?properties=1&user_id=${data['user_id']}&reference_id=${data['reference_id']}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    const response = await res.json();

    return response?.data;
  }
);

export const filterWalletTransactions = createAsyncThunk(
  'withdrawal/filterWalletTransactions',
  async (data, thunkAPI) => {
    const { token, team } = thunkAPI.getState().auth;

    let callback = () => {};

    if (data?.callback) {
      callback = data.callback;

      delete data.callback;
    }

    const formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    const res = await fetch(`${apiUrl}/wallet-transaction/filter/index`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Team: team,
      },
      body: formData,
    });
    const response = await res.json();
    if (response?.status_code !== 200) {
      return thunkAPI.rejectWithValue(response?.message);
    }
    return response?.data;
  }
);

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: {
    transaction: {},
    allTransactions: [],
    filterTransactions: [],
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(withdrawFromWallet.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(withdrawFromWallet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.transaction = action.payload;
    });
    builder.addCase(withdrawFromWallet.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getWalletTransactions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getWalletTransactions.fulfilled, (state, action) => {
      state.isLoading = false;

      state.transaction = action.payload;
    });
    builder.addCase(getWalletTransactions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(singleTransaction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(singleTransaction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transaction = action.payload;
    });
    builder.addCase(singleTransaction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(filterWalletTransactions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(filterWalletTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.current_page !== 1) {
        state.filterTransactions = {
          ...action.payload,
          data: [
            ...(state.filterTransactions?.data ?? []),
            ...(action.payload.data ?? []),
          ],
        };
        return;
      }
      state.filterTransactions = action.payload;
    });
    builder.addCase(filterWalletTransactions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default withdrawalSlice.reducer;
