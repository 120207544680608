import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEndpoint } from '../../helpers/connection';

const apiUrl = getEndpoint();

export const getMyPayment = createAsyncThunk(
  'payment/getMyPayment',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    try {
      const res = await fetch(`${apiUrl}/payment/me`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,

          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getPaymentProviders = createAsyncThunk(
  'payment/getPaymentProviders',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    try {
      const res = await fetch(`${apiUrl}/payment/show/provider`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,

          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyPayment = createAsyncThunk(
  'payment/verifyPayment',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;

    try {
      const res = await fetch(`${apiUrl}/payment/verify/cello`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,

          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await res.json();

      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const showAPayment = createAsyncThunk(
  'payment/showAPayment',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().auth;
    try {
      const res = await fetch(
        `${apiUrl}/payment/show?id=${data?.id}&properties=1`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,

            'Content-Type': 'application/json',
          },
        }
      );
      const response = await res.json();

      console.log('singlepay=====', response?.data);

      if (response?.status_code == 200) {
        return response?.data;
      } else {
        return thunkAPI.rejectWithValue('Invalid payment details');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    myPayments: [],
    payment: {},
    paymentProviders: {},
    isLoading: false,
    error: '',
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMyPayment.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyPayment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.myPayments = action.payload;
    });
    builder.addCase(getMyPayment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(showAPayment.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(showAPayment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.payment = action.payload;
    });
    builder.addCase(showAPayment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getPaymentProviders.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getPaymentProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paymentProviders = action.payload;
    });
    builder.addCase(getPaymentProviders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(verifyPayment.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(verifyPayment.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(verifyPayment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default paymentSlice.reducer;
