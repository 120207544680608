import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
  Th,
  Thead,
  Tr,
  CardHeader,
  Card,
  Center,
  Image,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import ArrowSvg from '../assets/svg/arrow-left.svg';
import WhiteArrowSvg from '../assets/svg/white-arrow-left.svg';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { GiReceiveMoney } from 'react-icons/gi';

import {
  autoEllipses,
  formatDate,
  formatNumberWithCommas,
  getDateWithTime,
  ucfirst,
} from '../helpers/helpers';
import {
  getApplicationSettings,
  toggleDarkMode,
} from '../redux/slices/settings';

import { showMyWallet } from '../redux/slices/wallet';
import {
  filterWalletTransactions,
  getWalletTransactions,
  singleTransaction,
} from '../redux/slices/withdrawal';

export default function AllWithdrawals() {
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authState = useSelector(state => state.auth);

  const settingsState = useSelector(state => state.settings);

  const walletState = useSelector(state => state.wallet);

  const withdrawState = useSelector(state => state.withdraw);

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(getApplicationSettings());
    dispatch(showMyWallet());
    dispatch(
      filterWalletTransactions({
        user_id: authState?.user?.id,
        page: 1,
        properties: 1,
      })
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const showWithdrawal = transaction => {
    dispatch(singleTransaction(transaction));
    navigate('/single-withdrawal');
  };

  const viewMore = () => {
    dispatch(
      filterWalletTransactions({
        user_id: authState?.user?.id,
        page: walletState?.myWallet?.current_page + 1,
        properties: 1,
      })
    );
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        pb={{ base: '90px', md: '20px' }}
      >
        <Card p="10px" h="50px" borderRadius={0} w="100%" alignSelf="center">
          <Flex direction="row" justifyContent="space-between">
            <Button
              onClick={() => navigate(`/dashboard`)}
              variant="ghost"
              alignSelf="flex-start"
              _hover={{ bg: 'transparent' }}
            >
              <Image
                src={colorMode === 'light' ? ArrowSvg : WhiteArrowSvg}
                mr={3}
              />
              <Text fontSize="16px">Withdrawals</Text>
            </Button>

            <Box
              alignSelf="center"
              pr="10px"
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode === 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Text
          fontSize="24px"
          lineHeight="18px"
          my="20px"
          fontWeight="500"
          color="rgba(142, 139, 139, 1)"
          px={{ base: '20px', md: '20px' }}
        >
          Balance
        </Text>
        <Flex direction={'row'} wrap={'wrap'} alignItems={'center'}>
          {(walletState?.myWallet?.data?.length ?? 0) > 0 &&
            walletState?.myWallet?.data?.map((item, i) => (
              <Card
                margin={'10px'}
                width={'172px'}
                borderRadius="lg"
                justifyContent={'center'}
                alignItems={'center'}
                key={i}
                onClick={() =>
                  item.balance > 0 &&
                  navigate('/withdraw', { state: { ...item } })
                }
              >
                <Text
                  fontSize="20px"
                  lineHeight="60px"
                  fontWeight="400"
                  alignItems="flex-end"
                >
                  {formatNumberWithCommas(item?.balance ?? 0)}
                  <span
                    style={{
                      marginLeft: '5px',
                      color: 'rgba(142, 139, 139, 1)',
                      fontSize: '10px',
                      fontWeight: '400',
                      lineHeight: '30px',
                    }}
                  >
                    {item?.currency_code ??
                      settingsState?.applicationSettings?.platform_currency
                        ?.alphabetic_code ??
                      'USD'}
                  </span>
                </Text>

                <Flex
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Text
                    color={
                      item.balance > 0
                        ? 'rgba(36, 107, 253, 1)'
                        : 'rgba(142, 139, 139, 1)'
                    }
                    fontWeight="500"
                    fontSize="14px"
                    alignSelf={'center'}
                    marginRight={'5px'}
                  >
                    Withdraw
                  </Text>
                  <GiReceiveMoney
                    style={{ marginBottom: 6 }}
                    color={
                      item.balance > 0
                        ? 'rgba(36, 107, 253, 1)'
                        : 'rgba(142, 139, 139, 1)'
                    }
                  />
                </Flex>
              </Card>
            ))}
        </Flex>

        {/* <Text
          fontSize="14px"
          lineHeight="18px"
          my="20px"
          fontWeight="500"
          color="rgba(142, 139, 139, 1)"
          px={{ base: '20px', md: '20px' }}
        >
          Balance
        </Text>

        <Flex
          mt="5px"
          mb="25px"
          direction="row"
          px={{ base: '20px', md: '20px' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            fontSize="40px"
            lineHeight="60px"
            fontWeight="400"
            alignItems="flex-end"
          >
            {(walletState?.myWallet?.data?.length ?? 0) > 0
              ? formatNumberWithCommas(
                  walletState.myWallet.data[0]?.balance ?? 0
                )
              : 0}
            <span
              style={{
                marginLeft: '5px',
                color: 'rgba(142, 139, 139, 1)',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '30px',
              }}
            >
              {settingsState?.applicationSettings?.platform_currency
                ?.alphabetic_code ?? 'USD'}
            </span>
          </Text>

          <Button
            alignSelf="center"
            borderRadius="8px"
            width="100px"
            height="34px"
            backgroundColor={
              ((walletState?.myWallet?.data ?? []).length ?? 0) > 0 &&
              parseFloat(walletState.myWallet.data[0]?.balance) > 0
                ? 'rgba(36, 107, 253, 1)'
                : 'gray'
            }
            onClick={() => {
              if (
                ((walletState?.myWallet?.data ?? []).length ?? 0) > 0 &&
                parseFloat(walletState.myWallet.data[0]?.balance) > 0
              ) {
                navigate('/withdraw');
              }
            }}
          >
            <Text
              fontSize="14px"
              fontWeight="700"
              lineHeight="18.23px"
              color="white"
            >
              Withdraw
            </Text>
          </Button>
        </Flex> */}

        <Center flexDirection="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            direction="row"
            mb="35px"
            px={{ base: '20px', md: '20px' }}
          >
            <Text
              fontSize="14px"
              lineHeight="23px"
              fontWeight="400"
              textAlign="center"
              color="rgba(155, 155, 155, 1)"
            >
              "Please note that withdrawals may take 2 working days to be
              processed."
            </Text>
          </Flex>

          {withdrawState.filterTransactions &&
            withdrawState.filterTransactions?.data &&
            (withdrawState.filterTransactions?.data ?? []).length > 0 && (
              <Box
                borderBottomColor="rgba(36, 107, 253, 1)"
                borderBottomWidth="4px"
                borderBottomLeftRadius="2px"
                borderBottomRightRadius="2px"
                paddingBottom="5px"
                marginRight="20px"
                mb={5}
              >
                <Text
                  color="rgba(36, 107, 253, 1)"
                  fontWeight="500"
                  fontSize="15px"
                >
                  Recent Activity
                </Text>
              </Box>
            )}

          {isLoading ? (
            <Skeleton
              noOfLines={1}
              mb={2}
              spacing="4"
              height="50px"
              width="90%"
              borderRadius="5px"
            />
          ) : (
            <Flex
              alignItems="center"
              flexDirection="column"
              maxH="60vh"
              width="100%"
              p={0}
              m={0}
              overflowY="auto"
            >
              {withdrawState.filterTransactions &&
                withdrawState.filterTransactions?.data &&
                withdrawState.filterTransactions?.data.map((item, index) => (
                  <Card
                    key={index}
                    width="90%"
                    py={4}
                    px={4}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                    onClick={() => showWithdrawal(item)}
                  >
                    <Flex>
                      <Text
                        fontSize="43px"
                        lineHeight="40px"
                        fontWeight="400"
                        mr={2}
                        color={
                          item?.status === 'completed'
                            ? 'rgba(0, 210, 32, 1)'
                            : item?.status === 'pending'
                            ? 'rgba(255, 169, 39, 1)'
                            : 'rgba(229, 20, 0, 1)'
                        }
                      >
                        {getDateWithTime(new Date(item?.created_at)).hours12}
                      </Text>
                      <Flex alignItems="center" direction="column">
                        <Text
                          fontSize="14px"
                          lineHeight="20px"
                          fontWeight="400"
                          color={
                            item?.status === 'completed'
                              ? 'rgba(0, 210, 32, 1)'
                              : item?.status === 'pending'
                              ? 'rgba(255, 169, 39, 1)'
                              : 'rgba(229, 20, 0, 1)'
                          }
                        >
                          {getDateWithTime(new Date(item?.created_at)).minutes}
                        </Text>
                        <Text
                          fontSize="14px"
                          lineHeight="20px"
                          fontWeight="400"
                          color="rgba(142, 139, 139, 0.8)"
                        >
                          {getDateWithTime(new Date(item?.created_at)).ampm}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex direction="column">
                      <Text fontSize="14px" lineHeight="18px" fontWeight="400">
                        {formatDate(new Date(item?.created_at))}
                      </Text>
                      <Text
                        fontSize="14px"
                        lineHeight="18px"
                        fontWeight="400"
                        color="rgba(142, 139, 139, 0.8)"
                      >
                        {autoEllipses(authState?.user?.email)}
                      </Text>
                    </Flex>

                    <Text fontSize="14px" lineHeight="18px" fontWeight="400">
                      {formatNumberWithCommas(item?.amount)}{' '}
                      <span style={{ color: 'rgba(142, 139, 139, 0.8)' }}>
                        {settingsState?.applicationSettings?.platform_currency
                          ?.alphabetic_code ?? 'USD'}
                      </span>
                    </Text>
                  </Card>
                ))}

              {withdrawState.filterTransactions?.current_page !==
                withdrawState.filterTransactions?.last_page &&
                !isLoading &&
                (withdrawState.filterTransactions?.data ?? []).length > 0 && (
                  <Text
                    fontSize="16px"
                    lineHeight="22.4px"
                    fontWeight="700"
                    color="rgba(36, 107, 253, 1)"
                    onClick={() => viewMore()}
                  >
                    View More
                  </Text>
                )}
            </Flex>
          )}
        </Center>
      </Flex>
    </>
  );
}
